import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import ExcerciseMobilePicture from "../assets/img/excercise_mobile.png";
import ExcercisePicture from "../assets/img/exercise_picture.png";

function HeleLichaam() {
  return (
    <>
      <Container fluid>
        <Row className="hele-section">
          <Col lg="6" md="6" className="p-0">
            <img
              src={ExcercisePicture}
              alt="ExcercisePicture"
              className="w-100 h-100 d-none d-md-block"
            />
            <img
              src={ExcerciseMobilePicture}
              alt="Picture"
              className="w-100 h-100 d-block d-md-none"
            />
          </Col>
          <Col
            lg="6"
            md="6"
            className="text-center px-md-5 d-flex flex-column justify-content-center"
          >
            <div className="  text-center extra-padding mx-lg-5 mx-0 m my-5">
              <h2 className="hele-section_heading text-left">
                VitaePro - goed voor
                <br />
                het hele lichaam
              </h2>
              <p className="hele-section_paragaph text-left">
                Spieren en gewrichten zijn belangrijk voor het functioneren van
                ons lichaam. Ze maken het mogelijk te bewegen, zorgen ervoor dat
                we kunnen ademen en houden ons hart kloppend. Om uw spieren en
                gewrichten te ondersteunen bevat VitaePro Boswellia serrata,
                mangaan en vitamine D.
                <br />
                <br />
                Het immuunsysteem is de natuurlijke afweer van het lichaam tegen
                ziekten. Het bestaat uit een verscheidenheid aan cellen en
                organen die samenwerking om uw lichaam tegen infecties te
                beschermen. De vitamine B12, C & D en seleen in VitaePro
                ondersteunen uw immuunsysteem en houdt het sterk.
                <br />
                <br />
                Vermoeidheid, of het gevoel dat u geen energie heeft ongeacht
                hoeveel slaap u krijgt is heel vervelend en kan u sterk
                beperken. Het kan door veel dingen worden veroorzaakt waaronder
                stress of slechte voeding. De vitamine C en B12 in VitaePro
                helpen vermoeidheid te{" "}
              </p>
              <Button className="p-0 m-0 w-100 px-2 py-2 my-1  hele-section_button text-center ">
                <span className="text-white text-uppercase button">
                  vertel mij meer
                </span>
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default HeleLichaam;
