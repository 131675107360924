import React from "react";
import ErvaarVitaePro from "../components/ErvaarVitaePro";
import Header from "../components/Header";
import HeleLichaam from "../components/HeleLichaam";
import PromotionSection from "../components/PromotionSection";

const Landing = () => {
  return (
    // <div className="bg-lite-blue"> // </div>
    <>
      <Header />
      <ErvaarVitaePro />
      <HeleLichaam />
      <PromotionSection />
    </>
  );
};

export default Landing;
