import { RepositoryFactory } from "../../repository/RepositoryFactory";
var LeadRepo = RepositoryFactory.get("Vitae");

export const postVitaeLead =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    await dispatch(createLoader(true));
    if (payload.bootCheck) {
      await dispatch(createLoader(false));
      onSuccess();
      dispatch({
        type: "CREATE_RESPONSE",
        payload: "data",
      });
    } else {
      try {
        let { data } = await LeadRepo.postVitaeLead(payload);
        if (data) {
          dispatch({
            type: "CREATE_RESPONSE",
            payload: data,
          });
          onSuccess();
          await dispatch(createLoader(false));
        } else {
          await dispatch(createLoader(false));
        }
      } catch (e) {
        console.log(e.response.data.error);
        if (e.response.data.error == "Conflict - duplicate") {
          await dispatch(createLoader(false));
          onSuccess();
          dispatch({
            type: "CREATE_RESPONSE",
            payload: "data",
          });
        } else {
          await dispatch(createLoader(false));
          let error = e.response.data.error.match(/'([^']+)'/)[0].slice(1, -1);
          dispatch({
            type: "ERROR_RESPONSE",
            payload: { [error]: true },
          });
        }
      }
    }
  };
export const setErrorResponse = () => async (dispatch) => {
  dispatch({
    type: "ERROR_RESPONSE",
    payload: null,
  });
};
export const createLoader = (val) => async (dispatch) => {
  dispatch({
    type: "CREATE_LOADER",
    payload: val,
  });
};
