import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import VitaePro from "../assets/img/VitaePro.png";
import RunningForm from "../assets/img/vitaepro_packshot.png";
import wingratis from "../assets/img/wingratis.png";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { postVitaeLead, setErrorResponse } from "../store/actions/VataeAction";
import ThankYouForm from "./ThankYouForm";
import ModalPrivacy from "./ModalPrivacy";
import ModalVoorwaarden from "./ModalVoorwaarden";

function Header() {
  const [bootCheck, setBootCheck] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [actievoorwaarden, setActievoorwaarden] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [siteSubId, setSiteSubId] = useState("");
  const [publisherId, setPublisherId] = useState("");

  let params = useParams();
  const history = useHistory();
  let dispatch = useDispatch();
  const { create_response, error_response } = useSelector(
    (state) => state.vatae
  );
  useEffect(() => {}, [error_response]);
  // console.log(create_response, "create_response");
  const { loading } = useSelector((state) => state.vatae);

  // const actievoorwaardenToggle = () => {
  //   setActievoorwaarden(!actievoorwaarden);
  // };
  // const privacyToggle = () => {
  //   setPrivacy(!privacy);
  // };

  const checkNameRegex = (val) => {
    let validName = /^([a-zA-Z ]){1,30}$/;
    // let validName = /^[a-zA-Z]+ [a-zA-Z]+$/;
    if (val != "") {
      if (validName.test(val)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const checkLastName = (val) => {
    let validName = /^([a-zA-Z ]){1,30}$/;
    if (val != "") {
      if (validName.test(val)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const checkEmailRegex = (val) => {
    let validName = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (val != "") {
      if (validName.test(val)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const checkPhoneRegex = (val) => {
    let validName = /^0\d{9}$/;
    if (val != "") {
      if (validName.test(val)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const HandleData = (e) => {
    e.preventDefault();
    if (
      checkNameRegex(firstName) === false &&
      checkLastName(lastName) === false &&
      checkEmailRegex(email) === false &&
      checkPhoneRegex(phoneNumber) === false
    ) {
      let Obj = {
        language: "nl_NL",
        publisher_id: publisherId,
        site_subid: siteSubId,
        site_custom_url: "https://vitaepro.nationalebelangen.nl",
        site_custom_name: "VitaePro",
        firstname: firstName,
        lastname: lastName,
        email: email,
        phone_number: phoneNumber,
        answers: ["3811"],
        bootCheck: bootCheck,
      };
      dispatch(postVitaeLead(Obj), () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
      });
    }
  };

  useEffect(() => {
    const query = history.location.search;
    if (query) {
      const site_subid = new URLSearchParams(query).get("oa_id");
      const publisher_id = new URLSearchParams(query).get("siteid");
      // console.log(site_subid, 'site_subid');
      setSiteSubId(site_subid);
      // console.log(publisher_id, ' publisher_id');
      setPublisherId(publisher_id);
    }
  }, []);

  return (
    <>
      <ModalPrivacy setPrivacy={setPrivacy} privacy={privacy} />
      <ModalVoorwaarden
        setActievoorwaarden={setActievoorwaarden}
        actievoorwaarden={actievoorwaarden}
      />
      <Container fluid className="Header">
        <Row>
          <Col xs="12" md="6" lg="6" className="Header_Form extra-padding py-4">
            <div className="my-3 d-flex  align-items-center justify-content-between Header_Form-text">
              <div className="Header_Form__vitaePro-logo">
                <img
                  src={VitaePro}
                  alt="VitaePro"
                  className="w-100 img-fluid my-auto"
                />
              </div>
              {/* <div className="ml-md-3 ml-lg-5"> */}
              <p className="Header_Form-paragraph align-self-center  mb-0">
                Maak kans op <br />6 maanden gratis VitaePro
              </p>
              {/* </div> */}
            </div>
            {/* <Container className="w-lg-50"> */}

            {create_response ? (
              <>
                <ThankYouForm />
              </>
            ) : (
              <>
                <Form
                  className=" w-lg-75 bg-white Header_Form-headerForm"
                  onSubmit={HandleData}
                >
                  <h5 className="Header_Form-headerText text-dark font-weight-bold">
                    VitaePro iets voor u? <br />
                    Vul uw gegevens in en maak kans op 6 maanden gratis VitaePro
                  </h5>
                  <Label className="mb-0 p-0">Voornaam</Label>
                  <Input
                    required
                    type="text"
                    value={firstName}
                    autoFocus="none"
                    className="p-0 m-0 mb-2 rounded-0 no-focus"
                    placeholder="bv. Pieter"
                    invalid={
                      checkNameRegex(firstName) || error_response?.firstname
                    }
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      error_response?.firstname && dispatch(setErrorResponse());
                    }}
                  />
                  <Label className=" mb-0 p-0">Achternaam</Label>
                  <Input
                    required
                    className="text-dark   p-0 m-0 mb-2  rounded-0 "
                    value={lastName}
                    placeholder="bv. de Jong"
                    invalid={
                      checkLastName(lastName) || error_response?.lastname
                    }
                    onChange={(e) => {
                      setLastName(e.target.value);
                      error_response?.lastname && dispatch(setErrorResponse());
                    }}
                  />

                  <Label className=" mb-0 p-0">Telefoonnummer</Label>
                  <Input
                    required
                    className="text-dark  p-0 m-0 mb-2 rounded-0 "
                    value={phoneNumber}
                    placeholder="bv. 0612345678"
                    invalid={
                      checkPhoneRegex(phoneNumber) ||
                      error_response?.phone_number
                    }
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                      error_response?.phone_number &&
                        dispatch(setErrorResponse());
                    }}
                  />
                  <Input
                    style={{ opacity: "0.001" }}
                    type="checkbox"
                    value={bootCheck}
                    onChange={() => setBootCheck(!bootCheck)}
                  />
                  <Label className=" mb-0 p-0">Email</Label>
                  <Input
                    required
                    className="text-dark  p-0 m-0 mb-2 rounded-0 "
                    placeholder="bv. piet@dejong.nl"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      error_response?.email && dispatch(setErrorResponse());
                    }}
                    invalid={checkEmailRegex(email) || error_response?.email}
                  />
                  {checkNameRegex(firstName) === false &&
                  checkLastName(lastName) === false &&
                  checkEmailRegex(email) === false &&
                  checkPhoneRegex(phoneNumber) === false ? (
                    <Button
                      type="submit"
                      className="p-0 m-0 w-100 px-2 py-2 mt-3  text-center "
                    >
                      {loading ? (
                        <Spinner color="white" size="md" />
                      ) : (
                        <span className="text-white text-uppercase button">
                          vertel mij meer
                        </span>
                      )}
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      className="p-0 m-0 w-100 px-2 py-2 mt-3  text-center "
                    >
                      <span className="text-white text-uppercase button">
                        vertel mij meer
                      </span>
                    </Button>
                  )}
                </Form>
              </>
            )}
            <p className="my-3   text-white text-left w-100  Header_PrivacyPolicy-text">
              Door mijn gegevens achter te laten, geef ik VitaeLab toestemming
              mij eenmalig telefonisch te benaderen en ga ik akkoord met de{" "}
              <u className="pointer" onClick={() => setPrivacy(true)}>
                privacy policy
              </u>{" "}
              en de{" "}
              <u className="pointer" onClick={() => setActievoorwaarden(true)}>
                actievoorwaarden
              </u>{" "}
              die aan deze actie verbonden zijn.
            </p>
          </Col>
          <Col
            xs="12"
            md="6"
            lang="6"
            className="Header_SeaSection align-items-center d-flex flex-column "
          >
            <div className="align-self-center Header_SeaSection-formImage my-md-auto my-5">
              <div className="Header_SeaSection-formImage__container text-left ">
                <p className="mb-5">
                  Al 20 jaar goed voor <br />
                  spieren en gewrichten
                </p>
                <div className="position-relative mt-5">
                  <img
                    src={RunningForm}
                    alt="RunningForm"
                    className="img_form"
                  />
                  <img src={wingratis} className="win_gratis" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Header;
