// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import VataeReducer from "./VataeReducer";

export let rootReducer = combineReducers({
  authUser: authUserReducer,
  vatae: VataeReducer,
});

export default rootReducer;
