import React from "react";
import { Label, Modal, ModalBody } from "reactstrap";

function ModalVoorwaarden(props) {
  const actievoorwaardenToggle = () => {
    props.setActievoorwaarden(!props.actievoorwaarden);
  };
  return (
    <Modal
      isOpen={props.actievoorwaarden}
      toggle={actievoorwaardenToggle}
      size="lg"
    >
      <ModalBody>
        <h2>Voorwaarden “6 MAANDEN GRATIS VITAEPRO” winactie</h2>
        <h3>ALGEMEEN</h3>
        <ol>
          <li>
            Deze voorwaarden zijn van toepassing op de 6 MAANDEN GRATIS
            VITAEPRO' actie (hierna: "Actie") van NUTRAQ B.V. (hierna:
            "NutraQ"). NutraQ is gevestigd aan de Teleportboulevard 120, 1043 EJ
            te Amsterdam.
          </li>
          <li>
            De Actie heeft als doel VitaePro te promoten. NutraQ handelt hierbij
            in overeenstemming met de Gedragscode promotionele kansspelen.
          </li>
          <li>
            Deelname aan de Actie is mogelijk van 01-01-2023 tot en met
            31-12-2023.
          </li>
          <li>
            De Organisator en de deelnemers zijn gebonden aan de inhoud en
            bepalingen van deze voorwaarden.
          </li>
          <li>
            De Organisator behoudt zich het recht voor om te allen tijde de
            Actie stop te zetten of de actievoorwaarden tussentijds te wijzigen
            op een wijze die niet in het nadeel is van de consument. Een
            eventuele herziene versie van de actievoorwaarden zal op passende
            wijze kenbaar worden gemaakt. In alle gevallen zal een dergelijke
            wijziging op de site www.vitaepro.nl worden geplaatst en zal de
            gewijzigde versie worden voorzien van een datum.
          </li>
          <li>
            Informatie over de wijze waarop NutraQ persoonsgegevens verwerkt in
            het kader van de Actie leest u in de privacyverklaring, die u kunt
            raadplegen via klantenservice@vitaelab.nl.
          </li>
        </ol>
        <h3>DEELNAME ACTIE</h3>

        <ol>
          <li>
            Deelname aan de Actie vereist dat u akkoord gaat met deze
            actievoorwaarden.
          </li>
          <li>Deelname aan de Actie is kosteloos.</li>
          <li>
            Deelname aan de Actie staat open voor:
            <br />
            natuurlijke personen die geen klant zijn bij VitaeLab en zich binnen
            de duur van de Actie hebben opgegeven voor de Actie en daarbij hun
            volledige naam, emailadres en telefoonnummer hebben achtergelaten op
            de daarvoor bestemde landingspagina.
          </li>
          <li>
            Om deel te nemen aan de Actie dient u tenminste 18 jaar oud te zijn
            en woonachtig te zijn in Nederland.
          </li>
          <li>Een persoon kan maximaal eenmaal deelnemen aan de Actie.</li>
          <li>Een deelnemer kan slechts één keer de prijs winnen.</li>
        </ol>
        <h3>PRIJZEN EN WINNAARS</h3>

        <ol>
          <li>
            Het totale prijzenpakket van de Actie omvat:
            <br />
            Maandelijks 4x 6 Maanden gratis VitaePro
          </li>
          <li>
            Een winnende uitkomst kan door een winnende deelnemer niet worden
            overgedragen aan een derde. Een winnende uitkomst kan niet worden
            ingewisseld voor iets anders, bijvoorbeeld geld, goederen en
            diensten.
          </li>
          <li>
            Werknemers van NutraQ en hun gezinsleden kunnen niet deelnemen aan
            de Actie.
          </li>
          <li>
            Trekking van de winnaars vindt maandelijks op de eerste werkdag van
            de volgende maand op onpartijdige wijze plaats.
          </li>
          <li>
            Winnaars worden binnen 14 dagen na de trekking op de hoogte gebracht
            van de gewonnen prijs. We gebruiken de persoonsgegevens die zijn
            opgegeven ten behoeve van deelname aan de Actie. We zullen contact
            opnemen per telefoon, e-mail en/of brief.
          </li>
          <li>
            Indien de winnaar niet binnen 14 dagen reageert op de
            contactpogingen van NutraQ B.V., vervalt het recht op de prijs.
          </li>
          <li>
            De prijs wordt per post of koerier bij de winnaar bezorgd op het aan
            ons kenbaar gemaakte adres.
          </li>
          <li>
            Voor eventuele klachten over gewonnen producten kunt u contact
            opnemen met de experts van onze VitaeLab klantenservice via e-mail
            (klantenservice@vitaelab.nl) of telefonisch (0800 38 38 003).
          </li>
          <li>
            NutraQ is bevoegd personen uit te sluiten van deelname bij (een
            vermoeden van) onrechtmatig handelen (zoals fraude) of het
            niet-voldoen aan deze voorwaarden.
          </li>
          <li>
            Deelnemers die geen prijs hebben gewonnen, worden hiervan niet op de
            hoogte gesteld.
          </li>
        </ol>
      </ModalBody>
    </Modal>
  );
}

export default ModalVoorwaarden;
