import React from "react";
import { Col, Container, Row } from "reactstrap";
import ManPromotionLogo from "../assets/img/man-running-promotionSection.png";
import HeartIcon from "../assets/img/heart-promotionSection.png";
import markIcon from "../assets/img/mark-promotionSection.png";

function PromotionSection() {
  return (
    <div className="bg-lite-white py-4">
      <Container fluid="lg">
        <Row className="promotionSection extra-padding">
          <Col sm="4" className="promotionSection__ManPromotion py-4">
            <div className="text-center">
              <img
                src={ManPromotionLogo}
                width="50px"
                height="50px"
                alt="ManPromotionLogo"
              />
              <p className="promotionSection__ManPromotion-paragraph">
                Voor soepele spieren en gewrichten
              </p>
            </div>
          </Col>
          <Col sm="4" className="promotionSection__HeartIcon  py-4">
            <div className="text-center">
              <img src={HeartIcon} alt="HeartIcon" width="50px" height="50px" />
              <p className="promotionSection__HeartIcon-paragraph">
                Voor een normale werking van het hart
              </p>
            </div>
          </Col>
          <Col sm="4" className="promotionSection__MarkIcon  py-4">
            <div className="text-center">
              <img src={markIcon} alt="MarkIcon" width="50px" height="50px" />
              <p className="promotionSection__MarkIcon-paragraph">
                Voor een goede weerstand
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PromotionSection;
