import React from "react";
import { Card, Col, Container, Row } from "reactstrap";
import MobileLayer from "../assets/img/mobileLayer.png";
import RunningLayer from "../assets/img/runningLayer.png";
import MarkLayer from "../assets/img/markLayer.png";
import HeartLayer from "../assets/img/heartLayer.png";
import LevelLayer from "../assets/img/levelLayer.png";
import Stars from "../assets/img/Starts.png";
import ViteaPro_Logo from "../assets/img/topBody-logo.png";

function ErvaarVitaePro() {
  return (
    <>
      <Container fluid="lg" className="ervaar my-5 extra-padding">
        <div className="d-flex my-2 justify-content-center">
          <div className="mr-1 align-self-center">
            <img src={Stars} alt="Stars" className="img-fluid" />
          </div>
          <div className="mx-1 align-self-center">
            <strong className="ervaar-review my-auto">290 reviews</strong>
          </div>
          <div className="ml-1">
            <img
              src={ViteaPro_Logo}
              alt="ViteaPro_Logo"
              className="img-fluid"
            />
          </div>
        </div>
        <h2 className="ervaar-heading text-center mt-md-0">
          Ingrediënten met een gedocumenteerd effect
        </h2>
        <Row className="ervaar__testimonial py-md-5">
          <Col xs="6" md="4" className="text-center">
            <Card className="border-0 d-flex flex-column">
              <div>
                <img src={MobileLayer} alt="mobile" className="img-fixed" />
              </div>
              <div>
                <h2 className="ervaar__testimonial-heading">GEWRICHTEN</h2>
                <p className="text-md-left text-center ervaar__testimonial-paragraph">
                  VitaePro bevat Boswellia serrata, voor behoud van soepele
                  gewrichten.
                </p>
              </div>
            </Card>
          </Col>
          <Col xs="6" md="4" className="text-center">
            <Card className="border-0 d-flex flex-column align-items-center justify-content-between">
              <div>
                <img src={RunningLayer} alt="Icon" className="img-fixed" />
              </div>
              <div>
                <h2 className="ervaar__testimonial-heading">SPIEREN</h2>
                <p className="text-md-left text-center ervaar__testimonial-paragraph">
                  De Vitamine D in VitaePro is goed voor het behoud van een
                  normale spierfunctie.
                </p>
              </div>
            </Card>
          </Col>
          <Col xs="6" md="4" className="text-center">
            <Card className="border-0 d-flex flex-column align-items-center justify-content-between">
              <div>
                <img src={MarkLayer} alt="MarkIcon" className="img-fixed" />
              </div>
              <div>
                <h2 className="ervaar__testimonial-heading">IMMUUNSYSTEEM</h2>
                <p className="text-md-left text-center ervaar__testimonial-paragraph">
                  De vitamine B12, seleen, vitamine C & D in VitaePro helpt mede
                  voor een goede weerstand.
                </p>
              </div>
            </Card>
          </Col>
          <Col xs="6" md="4" className="text-center">
            <Card className="border-0 d-flex flex-column align-items-center justify-content-between">
              <div>
                <img src={HeartLayer} alt="HeartIcon" className="img-fixed" />
              </div>
              <div>
                <h2 className="ervaar__testimonial-heading">HART</h2>
                <p className="text-md-left text-center ervaar__testimonial-paragraph">
                  VitaePro bevat omega 3-vetzuren DHA en EPA en ondersteund de
                  normale werking van het hart*.
                </p>
              </div>
            </Card>
          </Col>
          <Col xs="6" md="4" className="text-center">
            <Card className="border-0 d-flex flex-column align-items-center justify-content-between">
              <div>
                <img src={MarkLayer} alt="Logo" className="img-fixed" />
              </div>
              <div>
                <h2 className="ervaar__testimonial-heading">ANTIOXIDANTEN</h2>
                <p className="text-md-left text-center ervaar__testimonial-paragraph">
                  VitaePro bevat seleen, vitamine C en E die helpen cellen te
                  beschermen tegen oxidatieve schade.
                </p>
              </div>
            </Card>
          </Col>
          <Col xs="6" md="4" className="text-center">
            <Card className="border-0 d-flex flex-column align-items-center justify-content-between">
              <div>
                <img src={LevelLayer} alt="logo" className="img-fixed" />
              </div>
              <div>
                <h2 className="ervaar__testimonial-heading">ENERGIENIVEAU</h2>
                <p className="text-md-left text-center ervaar__testimonial-paragraph">
                  De vitamine C en B12 in VitaePro helpen vermoeidheid te
                  verminderen. 
                </p>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ErvaarVitaePro;
