import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import ViteaProHomeSection from "../assets/img/ViteaPro_Home.png";
import ModalAlgemene from "./ModalAlgemene";
import ModalIngredienten from "./ModalIngredienten";
import ModalPrivacy from "./ModalPrivacy";
import ModalVoorwaarden from "./ModalVoorwaarden";

const Footer = () => {
  const [privacy, setPrivacy] = useState(false);
  const [actievoorwaarden, setActievoorwaarden] = useState(false);
  const [algemenevoorwaarden, setAlgemenevoorwaarden] = useState(false);
  const [ingredienten, setIngredienten] = useState(false);
  return (
    <div className="bg-lite-blue py-5">
      <Container fluid="lg">
        <Row className="footer extra-padding">
          <Col
            sm="2"
            className="align-items-top footer__logo text-center mt-2 mt-md-0"
          >
            <img
              src={ViteaProHomeSection}
              alt="ViteaProHomeSection"
              className="img-fluid"
            />
          </Col>
          <Col sm="5" className="footer__contactinfo mt-3 mt-md-0">
            <p className="p-0 mb-0 footer__contactinfo-heading">
              Klantenservice
            </p>
            <p className="p-0 m-0 footer__contactinfo-subheading">
              Telefoon: 0800 383 8003
              <br /> Werkdagen 8:30-16:30 <br /> E-Mail:
              Klantenservice@vitaelab.nl
              <br />
              Antwoord binnen 2 werkdagen
              <br /> Postbus 8807, 1006JA Amsterdam
            </p>
          </Col>
          <Col sm="5" className="footer__copyright mt-5 mt-md-0">
            <p className="p-0 mb-0 footer__copyright-heading">Direct naar</p>
            <p
              className="p-0 m-0 footer__copyright-subheading pointer"
              onClick={() => setActievoorwaarden(true)}
            >
              Actievoorwaarden
            </p>
            <p
              className="p-0 m-0 footer__copyright-subheading pointer"
              onClick={() => setPrivacy(true)}
            >
              Privacy Statement
            </p>
            <p
              className="p-0 m-0 footer__copyright-subheading pointer"
              onClick={() => setAlgemenevoorwaarden(true)}
            >
              Algemene Voorwaarden
            </p>
            <p
              className="p-0 m-0 footer__copyright-subheading pointer"
              onClick={() => setIngredienten(true)}
            >
              Ingrediënten van VitaePro
            </p>
            <hr style={{ color: "#ffffff", border: "1px solid #ffffff" }} />
            <span className="footer__copyright-subheading">
              * Evaluatie gezondheidsclaim is lopend{" "}
            </span>
          </Col>
        </Row>
      </Container>

      <ModalPrivacy setPrivacy={setPrivacy} privacy={privacy} />
      <ModalVoorwaarden
        setActievoorwaarden={setActievoorwaarden}
        actievoorwaarden={actievoorwaarden}
      />
      <ModalAlgemene
        setAlgemenevoorwaarden={setAlgemenevoorwaarden}
        algemenevoorwaarden={algemenevoorwaarden}
      />

      <ModalIngredienten
        setIngredienten={setIngredienten}
        ingredienten={ingredienten}
      />
    </div>
  );
};

export default Footer;
