import React from "react";
import { Label, Modal, ModalBody } from "reactstrap";

function ModalPrivacy(props) {
  const privacyToggle = () => {
    props.setPrivacy(!props.privacy);
  };
  return (
    <Modal isOpen={props.privacy} toggle={privacyToggle} size="lg">
      <ModalBody>
        <Label>
          <h2>Ons Privacybeleid</h2>
          <p>
            Wij nemen de bescherming van de persoonsgegevens van onze klanten
            serieus en zorgen ervoor dat alle verwerkingen van persoonsgegevens
            in overeenstemming zijn met de toepasselijke wetgeving. Hieronder
            geven wij een beschrijving van de wijze waarop wij uw
            persoonsgegevens veilig verwerken.
          </p>

          <h3>
            1. Waarom wij uw persoonsgegevens verwerken en welke informatie wij
            verzamelen
          </h3>
          <p>
            NutraQ B.V. ("NutraQ") is verwerkingsverantwoordelijke in de zin van
            de wetgeving inzake gegevensbescherming. U kunt onze contactgegevens
            vinden in paragraaf 9. Als u zich registreert als klant verzoeken
            wij u uw volledige naam- en adresgegevens te verstrekken. Ook
            verzamelen wij betalingsgegevens. We doen dit om overeenkomsten
            tussen ons te kunnen uitvoeren, om de klantrelatie met u te kunnen
            beheren en om als dat nodig is contact met u te kunnen opnemen.
            Informatie wordt bewaard zo lang u actief klant bent bij ons.
            Sommige informatie kan ook worden bewaard na beëindiging van uw
            klantrelatie. De rechtsgrond voor deze verwerking is de noodzaak om
            een overeenkomst met u te kunnen uitvoeren of aangaan in de zin van
            artikel 6 lid 1 onder b van de Algemene Verordening
            Gegevensbescherming ("AVG").
          </p>
          <p>
            Als u ons uw persoonsgegevens verstrekt in verband met de koop, de
            registratie of de abonnementen, verwerken wij die persoonsgegevens
            om uw specifieke aanvraag te kunnen behandelen. Wij gebruiken de
            informatie ook om met u te communiceren en om onze dienstverlening
            aan te passen. Wij kunnen contact met u opnemen per brief,
            telefonisch, langs elektronische weg (inclusief SMS), tenzij u ons
            hebt laten weten dat u niet wenst dat wij contact met u opnemen. De
            rechtsgrond voor deze verwerking is de noodzaak om een overeenkomst
            met u te kunnen uitvoeren of aangaan in de zin van artikel 6 lid 1
            onder b AVG. Na beëindiging van de klantrelatie bewaren wij uw naam,
            adres en aankoophistorie om telefonisch of via geadresseerde post
            contact met u te kunnen opnemen met aanbiedingen en nieuws over onze
            producten waarvan wij menen dat die voor u interessant kunnen zijn,
            tenzij u ons hebt laten weten dat u niet wenst dat wij voor die
            doeleinden contact met u opnemen.
          </p>

          <p>
            Wij maken u erop attent dat, als u weigert ons persoonsgegevens te
            verstrekken die wij nodig hebben om een overeenkomst met u aan te
            gaan of om te voldoen aan een wettelijke verplichting, het mogelijk
            is dat wij u onze producten en diensten niet kunnen aanbieden.
          </p>

          <p>
            NutraQ is op grond van de toepasselijke belastingwetgeving verplicht
            om bepaalde informatie, waaronder uw betalingstransacties met
            betrekking tot eerdere bestellingen, te bewaren gedurende een
            periode van 7 jaar na afloop van het boekjaar. De rechtsgrond voor
            deze verwerking is dat de verwerking noodzakelijk is om te voldoen
            aan een wettelijke verplichting in de zin van artikel 6 lid 1 onder
            c AVG. NutraQ kan bepaalde boekhoudkundige informatie over uw
            betaalhistorie gebruiken om te controleren of u geen openstaande,
            achterstallige facturen hebt voor eerdere bestellingen alvorens wij
            uw nieuwe bestelling goedkeuren. De rechtsgrond voor deze verwerking
            is dat de verwerking noodzakelijk is ten behoeve van onze
            gerechtvaardigde belangen in de zin van artikel 6 lid 1 onder f AVG.
          </p>

          <p>
            Daarnaast kunnen uw persoonsgegevens worden gebruikt voor de
            voldoende doeleinden: administratie, facturering en
            accountantscontrole, betalingscontrole, verrekening van verdiende
            bonuspunten, administratieve en juridische doeleinden, statistieken-
            en marketinganalyses ter verbetering van onze dienstverlening,
            website- en gebruikerservaring, systeemtests, onderhoud en
            ontwikkeling, en ten behoeve van klantonderzoek en ‑enquêtes. De
            rechtsgrond voor deze behandeling is dat de verwerking noodzakelijk
            is ten behoeve van onze gerechtvaardigde belangen in de zin van
            artikel 6 lid 1 onder f AVG.
          </p>
          <p>
            nationalebelangen.nl
            <br /> Als u uw gegevens achterlaat op vitaepro.nationalebelangen.nl
            als blijk van uw verzoek om gecontacteerd te worden over VitaePro,
            gebruiken wij de door u verstrekte gegevens om aan dat
            contactverzoek te voldoen. Uw verzoek geldt in dat geval als het
            geven van toestemming voor de verwerking van uw gegevens. Wij zullen
            uw gegevens verwijderen als deze niet langer nodig zijn om u te
            contacteren, tenzij er een andere geldige aanleiding is om uw
            gegevens nog langer te bewaren (bijvoorbeeld als u zich als klant
            aanmeldt).
          </p>
          <h3>2. Hoe komen wij aan de informatie?</h3>
          <p>
            De persoonsgegevens die door NutraQ worden verwerkt zijn de
            persoonsgegevens die u zelf verstrekt bij uw registratie als bedoeld
            in paragraaf 1.
          </p>

          <p>
            Als wij persoonsgegevens van derden ontvangen, verstrekken wij u
            aanvullende informatie over de verwerking van die persoonsgegevens
            en over de derden van wie we die persoonsgegevens hebben verkregen.
          </p>

          <h3>3. Uitwisseling van persoonsgegevens met derden </h3>
          <p>
            Wij kunnen onderaannemers inschakelen om namens ons persoonsgegevens
            te verwerken. Deze onderaannemers kunnen in het buitenland gevestigd
            zijn. Als wij onderaannemers inschakelen, garanderen wij dat die
            onderaannemers contractueel verplicht zijn om passende maatregelen
            te treffen ter beveiliging van persoonsgegevens.
          </p>

          <p>
            Wij kunnen uw persoonsgegevens ook uitwisselen met ondernemingen
            binnen ons concern of met andere ondernemingen waarmee wij
            samenwerken voor doeleinden zoals bedoeld in paragraaf 1, zowel
            binnen als buiten de EU/EER. NutraQ is ervoor verantwoordelijk om
            ervoor te zorgen dat de doorgifte van uw persoonsgegevens
            plaatsvindt in overeenstemming met toepasselijke regelgeving middels
            intra-groepsvoorschriften.
          </p>

          <p>
            Wij kunnen uw persoonsgegevens bekendmaken aan anderen als we
            daartoe wettelijk verplicht zijn. Daarnaast maken wij uw gegevens
            uitsluitend bekend in overeenstemming met de toepasselijke
            wetgeving.{" "}
          </p>

          <h3>
            4. Nieuwsbrieven/direct marketing via elektronische communicatie
          </h3>
          <p>
            Wij bieden onze klanten de mogelijkheid om direct marketing te
            ontvangen met suggesties, tips en aanbiedingen. We kunnen uw
            persoonsgegevens gebruiken om u aanbiedingen en nieuws over onze
            producten te sturen waarvan wij menen dat die voor u interessant
            kunnen zijn voor zover ons dat is toegestaan op grond van de
            toepasselijke wetgeving. Wij kunnen dit doen als u ons hebt gevraagd
            om elektronische direct marketing door middel van uw registratie, of
            als wij uw elektronische adres hebben ontvangen in verband met de
            verkoop van onze producten. Wij doen ons best om passende informatie
            te verstrekken zodat deze voor u nuttig is. Als u deze informatie
            niet van ons wenst te ontvangen, vink dan niet het vakje aan voor
            marketing of vink het vakje "afmelden" aan voor marketing via
            elektronische communicatie voor onze eigen bijbehorende producten
            als u zich bij ons registreert. U kunt zich te allen tijde afmelden
            door de instructies te volgen die bij elk toekomstig
            marketingbericht aan u worden verstrekt. Aan het einde van de
            klantrelatie zullen wij u alleen nog aanbiedingen en nieuws
            toezenden via elektronische communicatie als u ons daarvoor
            toestemming hebt gegeven.{" "}
          </p>

          <h3>5. Beveiliging</h3>

          <p>
            Alle persoonsgegevens worden opgeslagen in een beveiligde omgeving.
            Onze website maakt gebruik van Secure Sockets Layer (SSL)
            encryptietechnologie. Dit is een van de meest geavanceerde
            beveiligingsprogramma's die op dit moment beschikbaar zijn voor
            internettransacties. Hiermee worden uw persoonsgegevens zodanig
            versleuteld dat de informatie niet op internet leesbaar is.
          </p>

          <h3>6. Correctie en verwijdering</h3>

          <p>
            Onjuiste of onvolledige persoonsgegevens of persoonsgegevens die wij
            niet mogen verwerken, worden door NutraQ op eigen initiatief dan wel
            op verzoek van u gecorrigeerd of verwijderd/geanonimiseerd.
          </p>

          <p>
            Uw persoonsgegevens worden verwijderd/geanonimiseerd als er niet
            langer een objectieve noodzaak is om deze bij NutraQ te bewaren,
            tenzij wij wettelijk verplicht zijn om de informatie langer te
            bewaren. NutraQ schat in dat de informatie bewaard moet blijven tot
            één (1) jaar na beëindiging van de klantrelatie. Alle
            persoonsgegevens die niet langer nodig zijn voor het doel van de
            opslag worden verwijderd/geanonimiseerd. De persoonsgegevens blijven
            slechts langer bewaard als wij daartoe wettelijk verplicht zijn, als
            u ons daarvoor toestemming hebt gegeven, of als dat noodzakelijk
            wordt geacht voor het instellen, uitoefenen of onderbouwen van
            rechtsvorderingen.
          </p>

          <h3>7. Uw rechten</h3>

          <p>
            Als wij persoonsgegevens over u verwerken, hebt u verschillende
            rechten op grond van de wetgeving inzake gegevensbescherming. In
            deze paragraaf vertellen wij u welke rechten u hebt en hoe u die
            kunt uitoefenen.
          </p>

          <p>
            <u>Recht van inzage</u>
          </p>
          <p>
            U hebt het recht om ons te vragen om informatie over onder meer de
            categorieën persoonsgegevens die wij over u aanhouden en de
            doeleinden waarvoor wij die verwerken, de (categorieën) ontvangers
            aan wie de informatie bekendgemaakt wordt, informatie over waar wij
            de gegevens vandaan hebben, etc. U hebt recht op ontvangst van een
            kopie van de persoonsgegevens die wij over u verwerken. Meer
            informatie over uw recht van inzage vindt u in artikel 15 AVG.
          </p>
          <p>
            <u>Recht op rectificatie</u>
          </p>
          <p>
            U hebt het recht om zonder onnodige vertraging onjuiste
            persoonsgegevens over u te laten rectificeren en u hebt, rekening
            houdend met de doeleinden voor de verwerking, het recht om
            onvolledige persoonsgegevens te laten aanvullen. Als u ontdekt dat
            er fouten zitten in de persoonsgegevens die wij over u hebben
            geregistreerd, wordt u verzocht schriftelijk contact met ons op te
            nemen, zodat de informatie kan worden gecorrigeerd. Meer informatie
            over uw recht op rectificatie vindt u in artikel 16 AVG.
          </p>
          <p>
            <u>Recht op gegevenswissing</u>
          </p>

          <p>
            In sommige gevallen hebt u het recht om (een deel van) uw
            persoonsgegevens door ons te laten wissen, bijvoorbeeld als u uw
            toestemming intrekt en wij geen andere rechtsgrond hebben om uw
            persoonsgegevens te blijven verwerken. Voor zover verdere verwerking
            van uw gegevens noodzakelijk is, bijvoorbeeld om ons in staat te
            stellen te voldoen aan onze wettelijke verplichtingen, of voor het
            instellen, uitoefenen of onderbouwen van rechtsvorderingen, zijn wij
            niet verplicht uw persoonsgegevens te verwijderen. Meer informatie
            over uw recht op gegevenswissing vindt u in artikel 17 AVG.
          </p>
          <p>
            <u>Recht op beperking van de verwerking</u>
          </p>

          <p>
            In sommige gevallen hebt u het recht om de verwerking van uw
            persoonsgegevens te beperken tot uitsluitend de opslag daarvan,
            bijvoorbeeld als u van mening bent dat de persoonsgegevens die wij
            over u verwerken onjuist zijn. Meer informatie over uw recht op
            beperking van de verwerking vindt u in artikel 18 AVG.
          </p>
          <p>
            <u>Recht op overdraagbaarheid van gegevens</u>
          </p>
          <p>
            In sommige gevallen kunt u het recht hebben om persoonsgegevens die
            u aan ons hebt verstrekt in een gestructureerde, gangbare en
            machineleesbare vorm te verkrijgen, en het recht hebben om, als dat
            technisch mogelijk is, die gegevens over te dragen aan een andere
            verwerkingsverantwoordelijke. Meer informatie over uw recht op
            overdraagbaarheid van gegevens vindt u in artikel 20 AVG.
          </p>
          <p>
            <u>Recht van bezwaar</u>
          </p>
          <p>
            U hebt te allen tijde het recht om bezwaar te maken tegen verwerking
            door ons van uw persoonsgegevens die wij verzamelen en verwerken.
            Als u bezwaar maakt tegen de verwerking, en mits dat bezwaar
            gerechtvaardigd is, hebben wij niet langer het recht uw
            persoonsgegevens te verwerken, tenzij wij kunnen aantonen dat wij
            gerechtvaardigde redenen hebben voor voortzetting van de verwerking.
            U hebt het onvoorwaardelijke recht om bezwaar te maken tegen de
            verwerking van uw gegevens ten behoeve van direct marketing. Meer
            informatie over uw recht van bezwaar vindt u in artikel 21 AVG.
          </p>
          <p>
            <u>Recht op intrekking van toestemming</u>
          </p>

          <p>
            U hebt te allen tijde het recht uw toestemming in te trekken. In
            sommige gevallen zullen wij uw persoonsgegevens kunnen blijven
            verwerken, ook als u uw toestemming intrekt. Dit is het geval als er
            een andere rechtsgrond bestaat voor de verwerking dan uw
            toestemming. Als u uw toestemming wenst in te trekken kunt u contact
            met ons opnemen op het in paragraaf 9 aangegeven e-mailadres.
          </p>
          <p>
            <u>Hoe kunt u uw rechten uitoefenen?</u>
          </p>

          <p>
            Als u een of meer van uw rechten wilt uitoefenen kunt u contact met
            ons opnemen zoals aangegeven in paragraaf 9.{" "}
          </p>

          <h3>8. Contact</h3>

          <p>
            NutraQ is de verwerkingsverantwoordelijke voor de verwerking van
            persoonsgegevens zoals hierboven beschreven. Wij zijn bereikbaar
            via:
          </p>
          <p>
            NutraQ B.V.
            <br />
            Postbus 8807, 1006 JA AMSTERDAM <br />
            Tel. 0800-38-38-003
            <br />
            klantenservice@vitaelab.nl
            <br />
          </p>

          <h3>9. Aanpassingen</h3>

          <p>
            Wij kunnen dit privacybeleid van tijd tot tijd aanpassen. Dat doen
            we in verband met wijzigingen in wetgeving of kadervoorwaarden, om
            commerciële redenen of om tegemoet te komen aan de behoeften van
            onze klanten, marketingpartners en dienstverleners. Bijgewerkte
            versies worden gepubliceerd op onze website, onder vermelding van de
            datum van de wijziging, zodat u kunt zien wanneer de laatste
            aanpassing heeft plaatsgevonden.
          </p>

          <p>Het privacybeleid is laatstelijk bijgewerkt op 21/6/2021.</p>
        </Label>
      </ModalBody>
    </Modal>
  );
}

export default ModalPrivacy;
