import React from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  Button,
  Form,
  Container,
  Row,
} from "reactstrap";

function ThankYouForm() {
  const { create_response } = useSelector((state) => state.vatae);
  //   console.log(create_response, "create_response");

  return (
    <>
      <Form className="my-auto d-flex p-4 bg-form-color form-height justify-content-center text-center align-items-center">
        <Container className="text-center">
          <h3 className="text-white  justify-content-center font-family-bold">
            Bedankt voor je aanvraag!
          </h3>
          <hr />
          <div className="text-left">
            <h3 className="text-white">
              Wij zullen binnen enkele dagen contact met je opnemen
            </h3>
            <h3 className="text-white">Met vriendelijke groet,</h3>
            <h3 className="text-white">Vitae Pro</h3>
          </div>
          <div className="text-left">
            {create_response?.profile?.id ? (
              <img
                referrerpolicy="no-referrer-when-downgrade"
                src={`https://republish.nationalebelangen.nl/m/5082/c1d8b3052f4f/?event=5913&unique_conversion_id=${create_response?.profile?.id}`}
                style={{
                  width: "50px",
                  height: "50px",
                  border: "0px",
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </Container>
      </Form>
    </>
  );
}

export default ThankYouForm;
