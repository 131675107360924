import React from "react";
import { Modal, ModalBody } from "reactstrap";

function ModalAlgemene(props) {
  const algemeneToggle = () => {
    props.setAlgemenevoorwaarden(!props.algemenevoorwaarden);
  };
  return (
    <Modal isOpen={props.algemenevoorwaarden} toggle={algemeneToggle} size="lg">
      <ModalBody>
        <div>
          <h3 class="text-primary font-weight-bold p-0 m-0">
            Algemene Voorwaarden Thuiswinkel
          </h3>
          <p>
            Deze Algemene Voorwaarden van de Nederlandse Thuiswinkel Organisatie
            (hierna: Thuiswinkel.org) zijn tot stand gekomen in overleg met de
            Consumentenbond in het kader van de Coördinatiegroep
            Zelfreguleringsoverleg (CZ) van de Sociaal-Economische Raad en
            treden in werking per 1 juni 2014.
          </p>
          <p>
            <a
              title="Algemene Voorwaarden"
              href="https://www.vitaepro.nl/globalassets/vitaepro-assets/common-images/local-content-nl/pdf/algemene-voorwaarden-thuiswinkel_27032020.pdf"
            >
              Download de algemene voorwaarden
            </a>
          </p>
          <p>
            <span class="size--medium">
              <strong>Inhoudsopgave:</strong>
            </span>
            <br />
            <a title="Definities" href="#a1" target="_top">
              Artikel&nbsp;&nbsp; 1 – Definities
            </a>
            <br />
            <a title="Identiteit van de ondernemer" href="#a2" target="_top">
              Artikel&nbsp;&nbsp; 2 - Identiteit van de ondernemer
            </a>
            <br />
            <a title="Toepasselijkheid" href="#a3" target="_top">
              Artikel&nbsp;&nbsp; 3 – Toepasselijkheid
            </a>
            <br />
            <a title="Het Aanbod" href="#a4" target="_top">
              Artikel&nbsp;&nbsp; 4 - Het aanbod
            </a>
            <br />
            <a title="De Overeenkomst" href="#a5" target="_top">
              Artikel&nbsp;&nbsp; 5 - De overeenkomst
            </a>
            <br />
            <a title="Herroepingsrecht" href="#a6" target="_top">
              Artikel&nbsp;&nbsp; 6 – Herroepingsrecht
            </a>
            <br />
            <a
              title="Verplichtingen van de consument tijdens de bedenktijd"
              href="#a7"
              target="_top"
            >
              Artikel&nbsp;&nbsp; 7 - Verplichtingen van de consument tijdens de
              bedenktijd
            </a>
            <br />
            <a
              title="Uitoefening van het herroepingsrecht door de consument en kosten daarvan"
              href="#a8"
              target="_top"
            >
              Artikel&nbsp;&nbsp; 8 - Uitoefening van het herroepingsrecht door
              de consument en kosten daarvan
            </a>
            <br />
            <a
              title="Verplichtingen van de ondernemer bij herroeping"
              href="#a9"
              target="_top"
            >
              Artikel&nbsp;&nbsp; 9 - Verplichtingen van de ondernemer bij
              herroeping
            </a>
            <br />
            <a title="Uitsluiting herroepingsrecht" href="#a10" target="_top">
              Artikel 10 - Uitsluiting herroepingsrecht
            </a>
            <br />
            <a title="De prijs" href="#a11" target="_top">
              Artikel 11 - De prijs
            </a>
            <br />
            <a title="Nakoming en extra garantie" href="#a12" target="_top">
              Artikel 12 - Nakoming en extra garantie
            </a>
            <br />
            <a title="Levering en uitvoering" href="#a13" target="_top">
              Artikel 13 - Levering en uitvoering
            </a>
            <br />
            <a
              title="Duurtransacties: duur, opzegging en verlenging"
              href="#a14"
              target="_top"
            >
              Artikel 14 - Duurtransacties: duur, opzegging en verlenging
            </a>
            <br />
            <a title="Betaling" href="#a15" target="_top">
              Artikel 15 – Betaling
            </a>
            <br />
            <a title="Klachtenregeling" href="#a16" target="_top">
              Artikel 16 – Klachtenregeling
            </a>
            <br />
            <a title="Geschillen" href="#a17" target="_top">
              Artikel 17 – Geschillen
            </a>
            <br />
            <a title="Branchegarantie" href="#a18" target="_top">
              Artikel 18 – Branchegarantie
            </a>
            <br />
            <a
              title="Aanvullende of afwijkende bepalingen"
              href="#a19"
              target="_top"
            >
              Artikel 19 - Aanvullende of afwijkende bepalingen
            </a>
            <br />
            <a
              title="Wijziging van de Algemene Voorwaarden Thuiswinkel"
              href="#a20"
              target="_top"
            >
              Artikel 20 - Wijziging van de Algemene Voorwaarden Thuiswinkel
            </a>
            <br />
            <a
              title="Aanvullende voorwaarden"
              href="#aanvullende_voorwaarden"
              target="_top"
            >
              Aanvullende voorwaarden
            </a>
            <br />
            <a title="Bijlagen" href="#bijlagen" target="_top">
              Bijlagen
            </a>
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="a1"></a>Artikel 1 – Definities
              </strong>
            </span>
          </p>
          <p>In deze voorwaarden wordt verstaan onder:</p>
          <p>
            1. Aanvullende overeenkomst: een overeenkomst waarbij de consument
            producten, digitale inhoud en/of diensten verwerft in verband met
            een overeenkomst op afstand en deze zaken, digitale inhoud en/of
            diensten door de ondernemer worden geleverd of door een derde partij
            op basis van een afspraak tussen die derde en de ondernemer;
          </p>
          <p>
            2. Bedenktijd: de termijn waarbinnen de consument gebruik kan maken
            van zijn herroepingsrecht;
          </p>
          <p>
            3. Consument: de natuurlijke persoon die niet handelt voor
            doeleinden die verband houden met zijn handels-, bedrijfs-,
            ambachts- of beroepsactiviteit;
          </p>
          <p>4. Dag: kalenderdag;</p>
          <p>
            5. Digitale inhoud: gegevens die in digitale vorm geproduceerd en
            geleverd worden;
          </p>
          <p>
            6. Duurovereenkomst: een overeenkomst die strekt tot de regelmatige
            levering van zaken, diensten en/of digitale inhoud gedurende een
            bepaalde periode;
          </p>
          <p>
            7. Duurzame gegevensdrager: elk hulpmiddel - waaronder ook begrepen
            e-mail - dat de consument of ondernemer in staat stelt om informatie
            die aan hem persoonlijk is gericht, op te slaan op een manier die
            toekomstige raadpleging of gebruik gedurende een periode die is
            afgestemd op het doel waarvoor de informatie is bestemd, en die
            ongewijzigde reproductie van de opgeslagen informatie mogelijk
            maakt;
          </p>
          <p>
            8. Herroepingsrecht: de mogelijkheid van de consument om binnen de
            bedenktijd af te zien van de overeenkomst op afstand;
          </p>
          <p>
            9. Ondernemer: de natuurlijke of rechtspersoon die lid is van
            Thuiswinkel.org en producten, (toegang tot) digitale inhoud en/of
            diensten op afstand aan consumenten aanbiedt;
          </p>
          <p>
            10. Overeenkomst op afstand: een overeenkomst die tussen de
            ondernemer en de consument wordt gesloten in het kader van een
            georganiseerd systeem voor verkoop op afstand van producten,
            digitale inhoud en/of diensten, waarbij tot en met het sluiten van
            de overeenkomst uitsluitend of mede gebruik gemaakt wordt van één of
            meer technieken voor communicatie op afstand;
          </p>
          <p>
            11. Modelformulier voor herroeping: het in Bijlage I van deze
            voorwaarden opgenomen Europese modelformulier voor herroeping;
            Bijlage I hoeft niet ter beschikking te worden gesteld als de
            consument ter zake van zijn bestelling geen herroepingsrecht heeft;
          </p>
          <p>
            12. Techniek voor communicatie op afstand: middel dat kan worden
            gebruikt voor het sluiten van een overeenkomst, zonder dat consument
            en ondernemer gelijktijdig in dezelfde ruimte hoeven te zijn
            samengekomen.
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="a2"></a>Artikel 2 - Identiteit van de ondernemer
              </strong>
            </span>
          </p>
          <p>
            Naam ondernemer: NutraQ B.V.
            <br />
            Handelend onder de naam/namen:
            <br />
            -&nbsp; VitaePro
          </p>
          <p>
            <strong>Vestigingsadres:</strong>
            <br />
            NutraQ B.V.
            <br />
            Teleportboulevard 120
            <br />
            1043 EJ, Amsterdam
            <br />
            Telefoonnummer: 0800 38 38 003
          </p>
          <p>
            <strong>Bereikbaarheid:</strong>
            <br />
            Van maandag t/m vrijdag vanaf 07.30 uur tot 16.30 uur
            <br />
            E-mailadres: klantenservice@vitaelab.nl
          </p>
          <p>
            KvK-nummer: 73991988
            <br />
            Btw-nummer: NL859735837B01
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="a3"></a>Artikel 3 - Toepasselijkheid
                <br />
              </strong>
            </span>
          </p>
          <p>
            1. Deze algemene voorwaarden zijn van toepassing op elk aanbod van
            de ondernemer en op elke tot stand gekomen overeenkomst op afstand
            tussen ondernemer en consument.
          </p>
          <p>
            2. Voordat de overeenkomst op afstand wordt gesloten, wordt de tekst
            van deze algemene voorwaarden aan de consument beschikbaar gesteld.
            Indien dit redelijkerwijs niet mogelijk is, zal de ondernemer
            voordat de overeenkomst op afstand wordt gesloten, aangeven op welke
            wijze de algemene voorwaarden bij de ondernemer zijn in te zien en
            dat zij op verzoek van de consument zo spoedig mogelijk kosteloos
            worden toegezonden.
          </p>
          <p>
            3. Indien de overeenkomst op afstand elektronisch wordt gesloten,
            kan in afwijking van het vorige lid en voordat de overeenkomst op
            afstand wordt gesloten, de tekst van deze algemene voorwaarden langs
            elektronische weg aan de consument ter beschikking worden gesteld op
            zodanige wijze dat deze door de consument op een eenvoudige manier
            kan worden opgeslagen op een duurzame gegevensdrager. Indien dit
            redelijkerwijs niet mogelijk is, zal voordat de overeenkomst op
            afstand wordt gesloten, worden aangegeven waar van de algemene
            voorwaarden langs elektronische weg kan worden kennisgenomen en dat
            zij op verzoek van de consument langs elektronische weg of op andere
            wijze kosteloos zullen worden toegezonden.
          </p>
          <p>
            4. Voor het geval dat naast deze algemene voorwaarden tevens
            specifieke product- of dienstenvoorwaarden van toepassing zijn, is
            het tweede en derde lid van overeenkomstige toepassing en kan de
            consument zich in geval van tegenstrijdige voorwaarden steeds
            beroepen op de toepasselijke bepaling die voor hem het meest gunstig
            is.
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="a4"></a>Artikel 4 - Het aanbod
              </strong>
            </span>
          </p>
          <p>
            1. Indien een aanbod een beperkte geldigheidsduur heeft of onder
            voorwaarden geschiedt, wordt dit nadrukkelijk in het aanbod vermeld.
          </p>
          <p>
            2. Het aanbod bevat een volledige en nauwkeurige omschrijving van de
            aangeboden producten, digitale inhoud en/of diensten. De
            beschrijving is voldoende gedetailleerd om een goede beoordeling van
            het aanbod door de consument mogelijk te maken. Als de ondernemer
            gebruik maakt van afbeeldingen, zijn deze een waarheidsgetrouwe
            weergave van de aangeboden producten, diensten en/of digitale
            inhoud. Kennelijke vergissingen of kennelijke fouten in het aanbod
            binden de ondernemer niet.
          </p>
          <p>
            3. Elk aanbod bevat zodanige informatie, dat voor de consument
            duidelijk is wat de rechten en verplichtingen zijn, die aan de
            aanvaarding van het aanbod zijn verbonden.
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="a5"></a>Artikel 5 - De overeenkomst
              </strong>
            </span>
          </p>
          <p>
            1. De overeenkomst komt, onder voorbehoud van het bepaalde in lid 4,
            tot stand op het moment van aanvaarding door de consument van het
            aanbod en het voldoen aan de daarbij gestelde voorwaarden.
          </p>
          <p>
            2. Indien de consument het aanbod langs elektronische weg heeft
            aanvaard, bevestigt de ondernemer onverwijld langs elektronische weg
            de ontvangst van de aanvaarding van het aanbod. Zolang de ontvangst
            van deze aanvaarding niet door de ondernemer is bevestigd, kan de
            consument de overeenkomst ontbinden.
          </p>
          <p>
            3. Indien de overeenkomst elektronisch tot stand komt, treft de
            ondernemer passende technische en organisatorische maatregelen ter
            beveiliging van de elektronische overdracht van data en zorgt hij
            voor een veilige webomgeving. Indien de consument elektronisch kan
            betalen, zal de ondernemer daartoe passende veiligheidsmaatregelen
            in acht nemen.
          </p>
          <p>
            4. De ondernemer kan zich binnen wettelijke kaders - op de hoogte
            stellen of de consument aan zijn betalingsverplichtingen kan
            voldoen, alsmede van al die feiten en factoren die van belang zijn
            voor een verantwoord aangaan van de overeenkomst op afstand. Indien
            de ondernemer op grond van dit onderzoek goede gronden heeft om de
            overeenkomst niet aan te gaan, is hij gerechtigd gemotiveerd een
            bestelling of aanvraag te weigeren of aan de uitvoering bijzondere
            voorwaarden te verbinden.
          </p>
          <p>
            5. De ondernemer zal uiterlijk bij levering van het product, de
            dienst of digitale inhoud aan de consument de volgende informatie,
            schriftelijk of op zodanige wijze dat deze door de consument op een
            toegankelijke manier kan worden opgeslagen op een duurzame
            gegevensdrager, meesturen:
          </p>
          <p>
            <em>
              a. het bezoekadres van de vestiging van de ondernemer waar de
              consument met klachten terecht kan;
            </em>
          </p>
          <p>
            <em>
              b. de voorwaarden waaronder en de wijze waarop de consument van
              het herroepingsrecht gebruik kan maken, dan wel een duidelijke
              melding inzake het uitgesloten zijn van het herroepingsrecht;
            </em>
          </p>
          <p>
            <em>
              c. de informatie over garanties en bestaande service na aankoop;
            </em>
          </p>
          <p>
            <em>
              d. de prijs met inbegrip van alle belastingen van het product,
              dienst of digitale inhoud; voor zover van toepassing de kosten van
              aflevering; en de wijze van betaling, aflevering of uitvoering van
              de overeenkomst op afstand;
            </em>
          </p>
          <p>
            <em>
              e. de vereisten voor opzegging van de overeenkomst indien de
              overeenkomst een duur heeft van meer dan één jaar of van
              onbepaalde duur is;
            </em>
          </p>
          <p>
            <em>
              f. indien de consument een herroepingsrecht heeft, het
              modelformulier voor herroeping.
            </em>
          </p>
          <p>
            6. In geval van een duurtransactie is de bepaling in het vorige lid
            slechts van toepassing op de eerste levering.
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="a6"></a>Artikel 6 – Herroepingsrecht
                <br />
              </strong>
            </span>
          </p>
          <p>Bij producten:</p>
          <p>
            1. De consument kan een overeenkomst met betrekking tot de aankoop
            van een product gedurende een bedenktijd van 14 dagen zonder opgave
            van redenen ontbinden. De ondernemer mag de consument vragen naar de
            reden van herroeping, maar deze niet tot opgave van zijn reden(en)
            verplichten.
          </p>
          <p>
            2. De in lid 1 genoemde bedenktijd gaat in op de dag nadat de
            consument, of een vooraf door de consument aangewezen derde, die
            niet de vervoerder is, het product heeft ontvangen, of:
          </p>
          <p>
            <em>
              a. als de consument in eenzelfde bestelling meerdere producten
              heeft besteld: de dag waarop de consument, of een door hem
              aangewezen derde, het laatste product heeft ontvangen. De
              ondernemer mag, mits hij de consument hier voorafgaand aan het
              bestelproces op duidelijke wijze over heeft geïnformeerd, een
              bestelling van meerdere producten met een verschillende levertijd
              weigeren.
            </em>
          </p>
          <p>
            <em>
              b. als de levering van een product bestaat uit verschillende
              zendingen of onderdelen: de dag waarop de consument, of een door
              hem aangewezen derde, de laatste zending of het laatste onderdeel
              heeft ontvangen;
            </em>
          </p>
          <p>
            <em>
              c. bij overeenkomsten voor regelmatige levering van producten
              gedurende een bepaalde periode: de dag waarop de consument, of een
              door hem aangewezen derde, het eerste product heeft ontvangen.
            </em>
          </p>
          <p>
            Bij diensten en digitale inhoud die niet op een materiële drager is
            geleverd:
          </p>
          <p>
            3. De consument kan een dienstenovereenkomst en een overeenkomst
            voor levering van digitale inhoud die niet op een materiële drager
            is geleverd gedurende 14 dagen zonder opgave van redenen ontbinden.
            De ondernemer mag de consument vragen naar de reden van herroeping,
            maar deze niet tot opgave van zijn reden(en) verplichten.
          </p>
          <p>
            4. De in lid 3 genoemde bedenktijd gaat in op de dag die volgt op
            het sluiten van de overeenkomst.
          </p>
          <p>
            Verlengde bedenktijd voor producten, diensten en digitale inhoud die
            niet op een materiële drager is geleverd bij niet informeren over
            herroepingsrecht:
          </p>
          <p>
            5. Indien de ondernemer de consument de wettelijk verplichte
            informatie over het herroepingsrecht of het modelformulier voor
            herroeping niet heeft verstrekt, loopt de bedenktijd af twaalf
            maanden na het einde van de oorspronkelijke, overeenkomstig de
            vorige leden van dit artikel vastgestelde bedenktijd.
          </p>
          <p>
            6. Indien de ondernemer de in het voorgaande lid bedoelde informatie
            aan de consument heeft verstrekt binnen twaalf maanden na de
            ingangsdatum van de oorspronkelijke bedenktijd, verstrijkt de
            bedenktijd 14 dagen na de dag waarop de consument die informatie
            heeft ontvangen.
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="a7"></a>Artikel 7 - Verplichtingen van de consument
                tijdens de bedenktijd
              </strong>
            </span>
          </p>
          <p>
            1. Tijdens de bedenktijd zal de consument zorgvuldig omgaan met het
            product en de verpakking. Hij zal het product slechts uitpakken of
            gebruiken in de mate die nodig is om de aard, de kenmerken en de
            werking van het product vast te stellen. Het uitgangspunt hierbij is
            dat de consument het product slechts mag hanteren en inspecteren
            zoals hij dat in een winkel zou mogen doen.
          </p>
          <p>
            2. De consument is alleen aansprakelijk voor waardevermindering van
            het product die het gevolg is van een manier van omgaan met het
            product die verder gaat dan toegestaan in lid 1.
          </p>
          <p>
            3. De consument is niet aansprakelijk voor waardevermindering van
            het product als de ondernemer hem niet voor of bij het sluiten van
            de overeenkomst alle wettelijk verplichte informatie over het
            herroepingsrecht heeft verstrekt.
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="a8"></a>Artikel 8 - Uitoefening van het herroepingsrecht
                door de consument en kosten daarvan
              </strong>
            </span>
          </p>
          <p>
            1. Als de consument gebruik maakt van zijn herroepingsrecht, meldt
            hij dit binnen de bedenktermijn door middel van het modelformulier
            voor herroeping of op andere ondubbelzinnige wijze aan de
            ondernemer.
          </p>
          <p>
            2. Zo snel mogelijk, maar binnen 14 dagen vanaf de dag volgend op de
            in lid 1 bedoelde melding, zendt de consument het product terug, of
            overhandigt hij dit aan (een gemachtigde van) de ondernemer. Dit
            hoeft niet als de ondernemer heeft aangeboden het product zelf af te
            halen. De consument heeft de terugzendtermijn in elk geval in acht
            genomen als hij het product terugzendt voordat de bedenktijd is
            verstreken.
          </p>
          <p>
            3. De consument zendt het product terug met alle geleverde
            toebehoren, indien redelijkerwijs mogelijk in originele staat en
            verpakking, en conform de door de ondernemer verstrekte redelijke en
            duidelijke instructies.
          </p>
          <p>
            4. Het risico en de bewijslast voor de juiste en tijdige uitoefening
            van het herroepingsrecht ligt bij de consument.
          </p>
          <p>
            5. De consument draagt de rechtstreekse kosten van het terugzenden
            van het product. Als de ondernemer niet heeft gemeld dat de
            consument deze kosten moet dragen of als de ondernemer aangeeft de
            kosten zelf te dragen, hoeft de consument de kosten voor
            terugzending niet te dragen.
          </p>
          <p>
            6. Indien de consument herroept na eerst uitdrukkelijk te hebben
            verzocht dat de verrichting van de dienst of de levering van gas,
            water of elektriciteit die niet gereed voor verkoop zijn gemaakt in
            een beperkt volume of bepaalde hoeveelheid aanvangt tijdens de
            bedenktijd, is de consument de ondernemer een bedrag verschuldigd
            dat evenredig is aan dat gedeelte van de verbintenis dat door de
            ondernemer is nagekomen op het moment van herroeping, vergeleken met
            de volledige nakoming van de verbintenis.
          </p>
          <p>
            7. De consument draagt geen kosten voor de uitvoering van diensten
            of de levering van water, gas of elektriciteit, die niet gereed voor
            verkoop zijn gemaakt in een beperkt volume of hoeveelheid, of tot
            levering van stadsverwarming, indien:
          </p>
          <p>
            <em>
              a. de ondernemer de consument de wettelijk verplichte informatie
              over het herroepingsrecht, de kostenvergoeding bij herroeping of
              het modelformulier voor herroeping niet heeft verstrekt, of;
            </em>
          </p>
          <p>
            <em>
              b. de consument niet uitdrukkelijk om de aanvang van de uitvoering
              van de dienst of levering van gas, water, elektriciteit of
              stadsverwarming tijdens de bedenktijd heeft verzocht.
            </em>
          </p>
          <p>
            8. De consument draagt geen kosten voor de volledige of
            gedeeltelijke levering van niet op een materiële drager geleverde
            digitale inhoud, indien:
          </p>
          <p>
            <em>
              a. hij voorafgaand aan de levering ervan niet uitdrukkelijk heeft
              ingestemd met het beginnen van de nakoming van de overeenkomst
              voor het einde van de bedenktijd;
            </em>
          </p>
          <p>
            <em>
              b. hij niet heeft erkend zijn herroepingsrecht te verliezen bij
              het verlenen van zijn toestemming; of
            </em>
          </p>
          <p>
            <em>
              c. de ondernemer heeft nagelaten deze verklaring van de consument
              te bevestigen.
            </em>
          </p>
          <p>
            9. Als de consument gebruik maakt van zijn herroepingsrecht, worden
            alle aanvullende overeenkomsten van rechtswege ontbonden.
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="a9"></a>Artikel 9 - Verplichtingen van de ondernemer bij
                herroeping
              </strong>
            </span>
          </p>
          <p>
            1. Als de ondernemer de melding van herroeping door de consument op
            elektronische wijze mogelijk maakt, stuurt hij na ontvangst van deze
            melding onverwijld een ontvangstbevestiging.
          </p>
          <p>
            2. De ondernemer vergoedt alle betalingen van de consument,
            inclusief eventuele leveringskosten door de ondernemer in rekening
            gebracht voor het geretourneerde product, onverwijld doch binnen 14
            dagen volgend op de dag waarop de consument hem de herroeping meldt.
            Tenzij de ondernemer aanbiedt het product zelf af te halen, mag hij
            wachten met terugbetalen tot hij het product heeft ontvangen of tot
            de consument aantoont dat hij het product heeft teruggezonden, naar
            gelang welk tijdstip eerder valt.
          </p>
          <p>
            3. De ondernemer gebruikt voor terugbetaling hetzelfde betaalmiddel
            dat de consument heeft gebruikt, tenzij de consument instemt met een
            andere methode. De terugbetaling is kosteloos voor de consument.
          </p>
          <p>
            4. Als de consument heeft gekozen voor een duurdere methode van
            levering dan de goedkoopste standaardlevering, hoeft de ondernemer
            de bijkomende kosten voor de duurdere methode niet terug te betalen.
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="a10"></a>Artikel 10 - Uitsluiting herroepingsrecht
              </strong>
            </span>
          </p>
          <p>
            De ondernemer kan de navolgende producten en diensten uitsluiten van
            het herroepingsrecht, maar alleen als de ondernemer dit duidelijk
            bij het aanbod, althans tijdig voor het sluiten van de overeenkomst,
            heeft vermeld:
          </p>
          <p>
            1. Producten of diensten waarvan de prijs gebonden is aan
            schommelingen op de financiële markt waarop de ondernemer geen
            invloed heeft en die zich binnen de herroepingstermijn kunnen
            voordoen
          </p>
          <p>
            2. Overeenkomsten die gesloten zijn tijdens een openbare veiling.
            Onder een openbare veiling wordt verstaan een verkoopmethode waarbij
            producten, digitale inhoud en/of diensten door de ondernemer worden
            aangeboden aan de consument die persoonlijk aanwezig is of de
            mogelijkheid krijgt persoonlijk aanwezig te zijn op de veiling,
            onder leiding van een veilingmeester, en waarbij de succesvolle
            bieder verplicht is de producten, digitale inhoud en/of diensten af
            te nemen;
          </p>
          <p>
            3. Dienstenovereenkomsten, na volledige uitvoering van de dienst,
            maar alleen als:
          </p>
          <p>
            <em>
              a. de uitvoering is begonnen met uitdrukkelijke voorafgaande
              instemming van de consument; en
            </em>
          </p>
          <p>
            <em>
              b. de consument heeft verklaard dat hij zijn herroepingsrecht
              verliest zodra de ondernemer de overeenkomst volledig heeft
              uitgevoerd;
            </em>
          </p>
          <p>
            4. Pakketreizen als bedoeld in artikel 7:500 BW en overeenkomsten
            van personenvervoer;&nbsp;
          </p>
          <p>
            5. Dienstenovereenkomsten voor terbeschikkingstelling van
            accommodatie, als in de overeenkomst een bepaalde datum of periode
            van uitvoering is voorzien en anders dan voor woondoeleinden,
            goederenvervoer, autoverhuurdiensten en catering;
          </p>
          <p>
            6. Overeenkomsten met betrekking tot vrijetijdsbesteding, als in de
            overeenkomst een bepaalde datum of periode van uitvoering daarvan is
            voorzien;
          </p>
          <p>
            7. Volgens specificaties van de consument vervaardigde producten,
            die niet geprefabriceerd zijn en die worden vervaardigd op basis van
            een individuele keuze of beslissing van de consument, of die
            duidelijk voor een specifieke persoon bestemd zijn;
          </p>
          <p>
            8. Producten die snel bederven of een beperkte houdbaarheid hebben;
          </p>
          <p>
            9. Verzegelde producten die om redenen van gezondheidsbescherming of
            hygiëne niet geschikt zijn om te worden teruggezonden en waarvan de
            verzegeling na levering is verbroken;
          </p>
          <p>
            10. Producten die na levering door hun aard onherroepelijk vermengd
            zijn met andere producten;
          </p>
          <p>
            11. Alcoholische dranken waarvan de prijs is overeengekomen bij het
            sluiten van de overeenkomst, maar waarvan de levering slechts kan
            plaatsvinden na 30 dagen, en waarvan de werkelijke waarde
            afhankelijk is van schommelingen van de markt waarop de ondernemer
            geen invloed heeft;
          </p>
          <p>
            12. Verzegelde audio-, video-opnamen en computerprogrammatuur,
            waarvan de verzegeling na levering is verbroken;
          </p>
          <p>
            13. Kranten, tijdschriften of magazines, met uitzondering van
            abonnementen hierop;
          </p>
          <p>
            14. De levering van digitale inhoud anders dan op een materiële
            drager, maar alleen als:
          </p>
          <p>
            <em>
              a. de uitvoering is begonnen met uitdrukkelijke voorafgaande
              instemming van de consument; en
            </em>
          </p>
          <p>
            <em>
              b. de consument heeft verklaard dat hij hiermee zijn
              herroepingsrecht verliest.
            </em>
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="a11"></a>Artikel 11 - De prijs
              </strong>
            </span>
          </p>
          <p>
            1. Gedurende de in het aanbod vermelde geldigheidsduur worden de
            prijzen van de aangeboden producten en/of diensten niet verhoogd,
            behoudens prijswijzigingen als gevolg van veranderingen in
            btw-tarieven.
          </p>
          <p>
            2. In afwijking van het vorige lid kan de ondernemer producten of
            diensten waarvan de prijzen gebonden zijn aan schommelingen op de
            financiële markt en waar de ondernemer geen invloed op heeft, met
            variabele prijzen aanbieden. Deze gebondenheid aan schommelingen en
            het feit dat eventueel vermelde prijzen richtprijzen zijn, worden
            bij het aanbod vermeld.
          </p>
          <p>
            3. Prijsverhogingen binnen 3 maanden na de totstandkoming van de
            overeenkomst zijn alleen toegestaan indien zij het gevolg zijn van
            wettelijke regelingen of bepalingen.
          </p>
          <p>
            4. Prijsverhogingen vanaf 3 maanden na de totstandkoming van de
            overeenkomst zijn alleen toegestaan indien de ondernemer dit
            bedongen heeft en:
          </p>
          <p>
            <em>
              a. deze het gevolg zijn van wettelijke regelingen of bepalingen;
              of
            </em>
          </p>
          <p>
            <em>
              b. de consument de bevoegdheid heeft de overeenkomst op te zeggen
              met ingang van de dag waarop de prijsverhoging ingaat.
            </em>
          </p>
          <p>
            5. De in het aanbod van producten of diensten genoemde prijzen zijn
            inclusief btw.
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="a12"></a>Artikel 12 - Nakoming overeenkomst en extra
                garantie
              </strong>
            </span>
          </p>
          <p>
            1. De ondernemer staat er voor in dat de producten en/of diensten
            voldoen aan de overeenkomst, de in het aanbod vermelde
            specificaties, aan de redelijke eisen van deugdelijkheid en/of
            bruikbaarheid en de op de datum van de totstandkoming van de
            overeenkomst bestaande wettelijke bepalingen en/of
            overheidsvoorschriften. Indien overeengekomen staat de ondernemer er
            tevens voor in dat het product geschikt is voor ander dan normaal
            gebruik.
          </p>
          <p>
            2. Een door de ondernemer, diens toeleverancier, fabrikant of
            importeur verstrekte extra garantie beperkt nimmer de wettelijke
            rechten en vorderingen die de consument op grond van de overeenkomst
            tegenover de ondernemer kan doen gelden indien de ondernemer is
            tekortgeschoten in de nakoming van zijn deel van de overeenkomst.
          </p>
          <p>
            3. Onder extra garantie wordt verstaan iedere verbintenis van de
            ondernemer, diens toeleverancier, importeur of producent waarin deze
            aan de consument bepaalde rechten of vorderingen toekent die verder
            gaan dan waartoe deze wettelijk verplicht is in geval hij is
            tekortgeschoten in de nakoming van zijn deel van de overeenkomst.
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="a13"></a>Artikel 13 - Levering en uitvoering
              </strong>
            </span>
          </p>
          <p>
            1. De ondernemer zal de grootst mogelijke zorgvuldigheid in acht
            nemen bij het in ontvangst nemen en bij de uitvoering van
            bestellingen van producten en bij de beoordeling van aanvragen tot
            verlening van diensten.
          </p>
          <p>
            2. Als plaats van levering geldt het adres dat de consument aan de
            ondernemer kenbaar heeft gemaakt.
          </p>
          <p>
            3. Met inachtneming van hetgeen hierover in artikel 4 van deze
            algemene voorwaarden is vermeld, zal de ondernemer geaccepteerde
            bestellingen met bekwame spoed doch uiterlijk binnen 30 dagen
            uitvoeren, tenzij een andere leveringstermijn is overeengekomen.
            Indien de bezorging vertraging ondervindt, of indien een bestelling
            niet dan wel slechts gedeeltelijk kan worden uitgevoerd, ontvangt de
            consument hiervan uiterlijk 30 dagen nadat hij de bestelling
            geplaatst heeft bericht. De consument heeft in dat geval het recht
            om de overeenkomst zonder kosten te ontbinden en recht op eventuele
            schadevergoeding.
          </p>
          <p>
            4. Na ontbinding conform het vorige lid zal de ondernemer het bedrag
            dat de consument betaald heeft onverwijld terugbetalen.
          </p>
          <p>
            5. Het risico van beschadiging en/of vermissing van producten berust
            bij de ondernemer tot het moment van bezorging aan de consument of
            een vooraf aangewezen en aan de ondernemer bekend gemaakte
            vertegenwoordiger, tenzij uitdrukkelijk anders is overeengekomen.
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="a14"></a>Artikel 14 - Duurtransacties: duur, opzegging en
                verlenging
                <br />
              </strong>
            </span>
            <br />
            <strong>
              Opzegging:
              <br />
            </strong>
            1. De consument kan een overeenkomst die voor onbepaalde tijd is
            aangegaan en die strekt tot het geregeld afleveren van producten
            (elektriciteit daaronder begrepen) of diensten, te allen tijde
            opzeggen met inachtneming van daartoe overeengekomen
            opzeggingsregels en een opzegtermijn van ten hoogste één maand.
          </p>
          <p>
            2. De consument kan een overeenkomst die voor bepaalde tijd is
            aangegaan en die strekt tot het geregeld afleveren van producten
            (elektriciteit daaronder begrepen) of diensten, te allen tijde tegen
            het einde van de bepaalde duur opzeggen met inachtneming van daartoe
            overeengekomen opzeggingsregels en een opzegtermijn van ten hoogste
            één maand.
          </p>
          <p>
            3. De consument kan de in de vorige leden genoemde overeenkomsten:
          </p>
          <p>
            <em>
              a. te allen tijde opzeggen en niet beperkt worden tot opzegging op
              een bepaald tijdstip of in een bepaalde periode;
            </em>
          </p>
          <p>
            <em>
              b. tenminste opzeggen op dezelfde wijze als zij door hem zijn
              aangegaan;
            </em>
          </p>
          <p>
            <em>
              c. altijd opzeggen met dezelfde opzegtermijn als de ondernemer
              voor zichzelf heeft bedongen.
            </em>
          </p>
          <p>
            <strong>
              Verlenging:
              <br />
            </strong>
            4. Een overeenkomst die voor bepaalde&nbsp;tijd is aangegaan en die
            strekt tot het geregeld afleveren van producten (elektriciteit
            daaronder begrepen) of diensten, mag niet stilzwijgend worden
            verlengd of vernieuwd voor een bepaalde duur.
          </p>
          <p>
            5. In afwijking van het vorige lid mag een overeenkomst die voor
            bepaalde tijd is aangegaan en die strekt tot het geregeld afleveren
            van dag- nieuws- en weekbladen en tijdschriften stilzwijgend worden
            verlengd voor een bepaalde duur van maximaal drie maanden, als de
            consument deze verlengde overeenkomst tegen het einde van de
            verlenging kan opzeggen met een opzegtermijn van ten hoogste één
            maand.
          </p>
          <p>
            6. Een overeenkomst die voor bepaalde tijd is aangegaan en die
            strekt tot het geregeld afleveren van producten of diensten, mag
            alleen stilzwijgend voor onbepaalde duur worden verlengd als de
            consument te allen tijde mag opzeggen met een opzegtermijn van ten
            hoogste één maand. De opzegtermijn is ten hoogste drie maanden in
            geval de overeenkomst strekt tot het geregeld, maar minder dan
            eenmaal per maand, afleveren van dag-, nieuws- en weekbladen en
            tijdschriften.
          </p>
          <p>
            7. Een overeenkomst met beperkte duur tot het geregeld ter
            kennismaking afleveren van dag-, nieuws- en weekbladen en
            tijdschriften (proef- of kennismakingsabonnement) wordt niet
            stilzwijgend voortgezet en eindigt automatisch na afloop van de
            proef- of kennismakingsperiode.
          </p>
          <p>
            <strong>Duur:</strong>
            <br />
            8. Als een overeenkomst een duur van meer dan een jaar heeft, mag de
            consument na een jaar de overeenkomst te allen tijde met een
            opzegtermijn van ten hoogste één maand opzeggen, tenzij de
            redelijkheid en billijkheid zich tegen opzegging vóór het einde van
            de overeengekomen duur verzetten.
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="a15"></a>Artikel 15 - Betaling
              </strong>
            </span>
          </p>
          <p>
            1. Voor zover niet anders is bepaald in de overeenkomst of
            aanvullende voorwaarden, dienen de door de consument verschuldigde
            bedragen te worden voldaan binnen 14 dagen na het ingaan van de
            bedenktermijn, of bij het ontbreken van een bedenktermijn binnen 14
            dagen na het sluiten van de overeenkomst. In geval van een
            overeenkomst tot het verlenen van een dienst, vangt deze termijn aan
            op de dag nadat de consument de bevestiging van de overeenkomst
            heeft ontvangen.
          </p>
          <p>
            2. Bij de verkoop van producten aan consumenten mag de consument in
            algemene voorwaarden nimmer verplicht worden tot vooruitbetaling van
            meer dan 50%. Wanneer vooruitbetaling is bedongen, kan de consument
            geen enkel recht doen gelden aangaande de uitvoering van de
            desbetreffende bestelling of dienst(en), alvorens de bedongen
            vooruitbetaling heeft plaatsgevonden.
          </p>
          <p>
            3. De consument heeft de plicht om onjuistheden in verstrekte of
            vermelde betaalgegevens onverwijld aan de ondernemer te melden.
          </p>
          <p>
            4. Indien de consument niet tijdig aan zijn
            betalingsverplichting(en) voldoet, is deze, nadat hij door de
            ondernemer is gewezen op de te late betaling en de ondernemer de
            consument een termijn van 14 dagen heeft gegund om alsnog aan zijn
            betalingsverplichtingen te voldoen, na het uitblijven van betaling
            binnen deze 14-dagen-termijn, over het nog verschuldigde bedrag de
            wettelijke rente verschuldigd en is de ondernemer gerechtigd de door
            hem gemaakte buitengerechtelijke incassokosten in rekening te
            brengen. Deze incassokosten bedragen maximaal: 15% over openstaande
            bedragen tot € 2.500,=; 10% over de daaropvolgende € 2.500,= en 5%
            over de volgende € 5.000,= met een minimum van € 40,=. De ondernemer
            kan ten voordele van de consument afwijken van genoemde bedragen en
            percentages.
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="a16"></a>Artikel 16 - Klachtenregeling
              </strong>
            </span>
          </p>
          <p>
            1. De ondernemer beschikt over een voldoende bekend gemaakte
            klachtenprocedure en behandelt de klacht overeenkomstig deze
            klachtenprocedure.
          </p>
          <p>
            2. Klachten over de uitvoering van de overeenkomst moeten binnen
            bekwame tijd nadat de consument de gebreken heeft geconstateerd,
            volledig en duidelijk omschreven worden ingediend bij de ondernemer.
          </p>
          <p>
            3. Bij de ondernemer ingediende klachten worden binnen een termijn
            van 14 dagen gerekend vanaf de datum van ontvangst beantwoord. Als
            een klacht een voorzienbaar langere verwerkingstijd vraagt, wordt
            door de ondernemer binnen de termijn van 14 dagen geantwoord met een
            bericht van ontvangst en een indicatie wanneer de consument een meer
            uitvoerig antwoord kan verwachten.
          </p>
          <p>
            4. Een klacht over een product, dienst of de service van de
            ondernemer kan eveneens worden ingediend via een klachtenformulier
            op de consumentenpagina van de website van Thuiswinkel.org
            www.thuiswinkel.org. De klacht wordt dan zowel naar de betreffende
            ondernemer als naar Thuiswinkel.org gestuurd.
          </p>
          <p>
            5. De consument dient de ondernemer in ieder geval 4 weken de tijd
            te geven om de klacht in onderling overleg op te lossen. Na deze
            termijn ontstaat een geschil dat vatbaar is voor de
            geschillenregeling.
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="a17"></a>Artikel 17 - Geschillen
              </strong>
            </span>
          </p>
          <p>
            1. Op overeenkomsten tussen de ondernemer en de consument waarop
            deze algemene voorwaarden betrekking hebben, is uitsluitend
            Nederlands recht van toepassing.
          </p>
          <p>
            2. Geschillen tussen de consument en de ondernemer over de
            totstandkoming of uitvoering van overeenkomsten met betrekking tot
            door deze ondernemer te leveren of geleverde producten en diensten,
            kunnen, met inachtneming van het hierna bepaalde, zowel door de
            consument als de ondernemer worden voorgelegd aan de
            Geschillencommissie Thuiswinkel, Postbus 90600, 2509 LP te Den Haag
            (www.sgc.nl).
          </p>
          <p>
            3. Een geschil wordt door de Geschillencommissie slechts in
            behandeling genomen, indien de consument zijn klacht eerst binnen
            bekwame tijd aan de ondernemer heeft voorgelegd.
          </p>
          <p>
            4. Leidt de klacht niet tot een oplossing dan moet het geschil
            uiterlijk&nbsp;12 maanden na de datum waarop de consument de klacht
            bij de ondernemer indiende, schriftelijk of in een andere door de
            Commissie te bepalen vorm bij de Geschillencommissie aanhangig
            worden gemaakt.
          </p>
          <p>
            5. Wanneer de consument een geschil wil voorleggen aan de
            Geschillencommissie, is de ondernemer aan deze keuze gebonden. Bij
            voorkeur meldt de consument dit eerst aan de ondernemer.
          </p>
          <p>
            6. Wanneer de ondernemer een geschil wil voorleggen aan de
            Geschillencommissie, zal de consument binnen vijf weken na een
            daartoe door de ondernemer schriftelijk gedaan verzoek, schriftelijk
            dienen uit te spreken of hij zulks ook wenst dan wel het geschil wil
            laten behandelen door de daartoe bevoegde rechter. Verneemt de
            ondernemer de keuze van de consument niet binnen de termijn van vijf
            weken, dan is de ondernemer gerechtigd het geschil voor te leggen
            aan de bevoegde rechter.&nbsp;
          </p>
          <p>
            7. De Geschillencommissie doet uitspraak onder de voorwaarden zoals
            deze zijn vastgesteld in het reglement van de Geschillencommissie
            (www.degeschillencommissie.nl/over-ons/de-commissies/2404/thuiswinkel).
            De beslissingen van de Geschillencommissie geschieden bij wege van
            bindend advies.
          </p>
          <p>
            8. De Geschillencommissie zal een geschil niet behandelen of de
            behandeling staken, indien aan de ondernemer surseance van betaling
            is verleend, deze in staat van faillissement is geraakt of zijn
            bedrijfsactiviteiten feitelijk heeft beëindigd, voordat een geschil
            door de commissie op de zitting is behandeld en een einduitspraak is
            gewezen.
          </p>
          <p>
            9. Indien naast de Geschillencommissie Thuiswinkel een andere
            erkende of bij de Stichting Geschillencommissies voor
            Consumentenzaken (SGC) of het Klachteninstituut Financiële
            Dienstverlening (Kifid) aangesloten geschillencommissie bevoegd is,
            is voor geschillen betreffende hoofdzakelijk de methode van verkoop
            of dienstverlening op afstand de Geschillencommissie Thuiswinkel bij
            voorkeur bevoegd. Voor alle overige geschillen de andere erkende bij
            SGC of Kifid aangesloten geschillencommissie.
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="a18"></a>Artikel 18 - Branchegarantie
              </strong>
            </span>
          </p>
          <p>
            1. Thuiswinkel.org staat garant voor de nakoming van de bindende
            adviezen van de Geschillencommissie Thuiswinkel door haar leden,
            tenzij het lid besluit het bindend advies binnen twee maanden na de
            verzending ervan ter toetsing aan de rechter voor te leggen. Deze
            garantstelling herleeft, indien het bindend advies na toetsing door
            de rechter in stand is gebleven en het vonnis waaruit dit blijkt, in
            kracht van gewijsde is gegaan. Tot maximaal een bedrag van €10.000,-
            per bindend advies, wordt dit bedrag door Thuiswinkel.org aan de
            consument uitgekeerd. Bij bedragen groter dan €10.000,- per bindend
            advies, wordt €10.000,- uitgekeerd. Voor het meerdere heeft
            Thuiswinkel.org een inspanningsverplichting om ervoor te zorgen dat
            het lid het bindend advies nakomt.
          </p>
          <p>
            2. Voor toepassing van deze garantie is vereist dat de consument een
            schriftelijk beroep hierop doet bij Thuiswinkel.org en dat hij zijn
            vordering op de ondernemer overdraagt aan Thuiswinkel.org. Indien de
            vordering op de ondernemer meer bedraagt dan €10.000,-, wordt de
            consument aangeboden zijn vordering voor zover die boven het bedrag
            van €10.000,- uitkomt over te dragen aan Thuiswinkel.org, waarna
            deze organisatie op eigen naam en kosten de betaling daarvan in
            rechte zal vragen ter voldoening aan de consument.
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="a19"></a>Artikel 19 - Aanvullende of afwijkende
                bepalingen
              </strong>
            </span>
          </p>
          <p>
            Aanvullende dan wel van deze algemene voorwaarden afwijkende
            bepalingen mogen niet ten nadele van de consument zijn en dienen
            schriftelijk te worden vastgelegd dan wel op zodanige wijze dat deze
            door de consument op een toegankelijke manier kunnen worden
            opgeslagen op een duurzame gegevensdrager.
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="a20"></a>Artikel 20 - Wijziging van de Algemene
                Voorwaarden Thuiswinkel
              </strong>
            </span>
          </p>
          <p>
            1. Thuiswinkel.org zal deze algemene voorwaarden niet wijzigen dan
            in overleg met de Consumentenbond.
          </p>
          <p>
            2. Wijzigingen in deze voorwaarden zijn slechts van kracht nadat
            deze op daartoe geëigende wijze zijn gepubliceerd, met dien
            verstande, dat bij toepasselijke wijzigingen gedurende de looptijd
            van een aanbod de voor de consument meest gunstige bepaling zal
            prevaleren.
          </p>
          <p>
            Thuiswinkel.org
            <br />
            www.thuiswinkel.org
            <br />
            Horaplantsoen 20, 6717 LT Ede
            <br />
            Postbus 7001, 6710 CB Ede
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="aanvullende_voorwaarden"></a>Aanvullende Voorwaarden
                <br />
              </strong>
            </span>
          </p>
          <p>
            <strong>Abonnement</strong>
            <br />
            NutraQ verkoopt de producten op basis van een doorlopend abonnement,
            waarbij de eerste levering gewoonlijk na 14 dagen plaatsvindt en
            voorts volgens de gekozen leveringsfrequentie, normaliter elke twee
            maanden. Er geldt geen verplichting tot aankoop of minimumduur.
          </p>
          <p>
            <strong>Wijzigingen</strong>
            <br />
            Er kunnen wijzigingen worden aangebracht in producten, leveringsvorm
            en leveringsfrequentie. Wijzigingen worden ruimschoots voor de
            inwerkingtreding van de wijzigingen aangekondigd. Kleine
            wijzigingen, zoals een gewijzigde verpakking, worden doorgegeven via
            onze informatie op onze websites.
          </p>
          <p>
            Een abonnement kan worden geweigerd op objectieve gronden, zoals
            pogingen tot fraude of gebrek aan kredietwaardigheid.
          </p>
          <p>
            U kunt op ieder moment contact opnemen met onze klantenservice om uw
            abonnement te onderbreken, te wijzigen of te annuleren.
          </p>
          <p>
            <strong>Garantie</strong>
            <br />
            Volledige garantie wordt gegeven in overeenstemming met het
            toepasselijke recht, waaronder wordt begrepen de conformiteit van de
            goederen en dat de goederen vrij zijn van gebreken.
          </p>
          <p>
            De garantie is beperkt tot de houdbaarheidsdatum van het product
            zoals aangegeven op de verpakking, mits het correct is bewaard.
            Daarnaast dienen alle klachten met betrekking tot gebreken aan ons
            te worden gemeld maximaal binnen twee maanden na ontdekking van het
            gebrek. In ieder geval dient de koper een gebrek te melden binnen
            een redelijke termijn na ontdekking. NutraQ vergoedt alle
            verzendkosten in geval van klachten, fouten en gebreken. Als u een
            beroep wilt doen op de garantie of als u vragen hebt in verband met
            klachten, kunt u contact opnemen met onze klantenservice.
          </p>
          <p>
            <strong>Voorbehouden en beperking van aansprakelijkheid</strong>
            <br />
            NutraQ behoudt zich het recht voor om haar rechten en verplichtingen
            uit hoofde van deze overeenkomst over te dragen aan een andere
            partij. Echter, indien u op grond van die overdracht van rechten en
            verplichtingen de overeenkomst wenst te ontbinden, dan bent u
            daartoe gerechtigd.
          </p>
          <p>
            NutraQ maakt een voorbehoud in verband met storingen, onjuistheden
            in prijzen of leveringsproblemen als gevolg van omstandigheden die
            buiten haar invloedssfeer liggen.
          </p>
          <p>
            Voorts maakt NutraQ een voorbehoud in verband met drukfouten,
            onjuistheden in prijzen en BTW- en andere belastingwijzigingen. Alle
            aanbiedingen gelden zolang de voorraad strekt (zoals vermeld in het
            kader van de specifieke actie).
          </p>
          <p>
            Wij zijn niet verantwoordelijk voor onjuist gebruik van producten. U
            dient de aanbevolen dosering te volgen zoals die is aangegeven op de
            verpakking.
          </p>
          <p>
            <strong>Wijzigingen in de aanvullende voorwaarden</strong>
            <br />
            De voorwaarden kunnen worden gewijzigd wanneer dat nodig mocht zijn
            en/of in het geval van publiekrechtelijke wijzigingen of bevelen die
            van invloed zijn op deze voorwaarden. Als de voorwaarden worden
            gewijzigd, wordt u ten minste één maand voor de inwerkingtreding van
            die wijzigingen op de hoogte gebracht. Als u de overeenkomst wenst
            te ontbinden op grond van de gewijzigde voorwaarden, dan bent u
            daartoe gerechtigd. Als de overeenkomst tussen partijen niet wordt
            beëindigd houdt dat in dat u akkoord gaat met de gewijzigde
            voorwaarden. Nieuwe voorwaarden worden toegezonden aan onze klanten
            en aangekondigd op onze website.
          </p>
          <p>
            <span class="size--medium">
              <strong>
                <a id="bijlagen"></a>Bijlagen
                <br />
              </strong>
            </span>
            Bijlage I:{" "}
            <a
              title="Modelformulier voor herroeping"
              href="https://www.vitaepro.nl/globalassets/vitaepro-assets/common-images/local-content-nl/pdf/modelformulier-voor-herroeping.pdf"
              target="_blank"
              rel="noopener"
            >
              Modelformulier voor herroeping
            </a>
            <br />
            Bijlage II:{" "}
            <a
              title="Algemene Voorwaarden Thuiswinkel Waarborg"
              href="https://www.vitaepro.nl/globalassets/vitaepro-assets/common-images/local-content-nl/pdf/algemene-voorwaarden-thuiswinkel_27032020.pdf"
            >
              Algemene Voorwaarden Thuiswinkel
            </a>
            <br />
            Bijlage III:{" "}
            <a
              title="Aanvullende Voorwaarden"
              href="https://www.vitaepro.nl/globalassets/vitaepro-assets/common-images/local-content-nl/pdf/aanvullende-voorwaarden.pdf"
              target="_blank"
              rel="noopener"
            >
              Aanvullende Voorwaarden
            </a>
          </p>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ModalAlgemene;
