import Footer from "../components/Footer";

const Main = (props) => {
  return (
    <>
      {/* <div className="d-flex flex-column " style={{ minHeight: "100vh" }}> */}
      {/* style={{ flexGrow: 1 }} */}
      <div>{props.children}</div>
      <Footer />
      {/* </div> */}
    </>
  );
};

export default Main;
