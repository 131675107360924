import React from "react";
import { Modal, ModalBody } from "reactstrap";

function ModalIngredienten(props) {
  const ingredientenToggle = () => {
    props.setIngredienten(!props.ingredienten);
  };
  return (
    <Modal isOpen={props.ingredienten} toggle={ingredientenToggle} size="lg">
      <ModalBody>
        <div>
          <h3 className="text-primary font-weight-bold p-0 m-0">
            Ingrediënten
          </h3>
          <p>
            VitaePro is een uniek voedingssupplement met ingrediënten die
            schoon, veilig en grondig getest zijn. Bovendien bevat VitaePro
            gedocumenteerd actieve ingrediënten voor belangrijke functies in het
            lichaam.
          </p>
          <h4 className="text-primary font-weight-bold p-0 m-0">
            Over VitaePro
          </h4>
          <p>
            VitaePro is een voedingssupplement met een unieke combinatie van
            plantenextract,&nbsp;
            <a
              title="Omega 3 Vetzuren"
              href="https://www.vitaepro.nl/kennisbank/vetten/"
            >
              omega-3-vetzuren EPA en DHA
            </a>
            , belangrijke vitamines en antioxidanten (o.a.&nbsp;
            <a
              title="Vitamine C"
              href="https://www.vitaepro.nl/kennisbank/vitamines/vitamine-c/"
            >
              vitamine C
            </a>
            ,&nbsp;
            <a
              title="Vitamine E"
              href="https://www.vitaepro.nl/kennisbank/vitamines/vitamine-e/"
            >
              E
            </a>
            &nbsp;en&nbsp;
            <a
              title="Seleen"
              href="https://www.vitaepro.nl/kennisbank/mineralen-spoorelementen/selenium/"
            >
              selenium
            </a>
            ).
          </p>
          <p>
            VitaePro wordt veilig geproduceerd, volgens de GMP-norm (Good
            Manufacturing Practice) en is gemaakt met behulp van goed
            gedocumenteerde grondstoffen van gerenommeerde leveranciers.
          </p>
          <p>
            VitaePro krijgt zijn donkerrode kleur door de{" "}
            <a
              title="Alles over Astaxanthine"
              href="https://www.vitaepro.nl/kennisbank/astaxanthine/"
            >
              <strong>astaxanthine</strong>
            </a>{" "}
            in VitaePro. Astaxanthine is een carotenoïde; kleurstoffen die onder
            andere zorgen voor de oranjerode kleur van wortels en tomaten. Ook
            komt het voor in garnalen, schaaldieren, krill en microalgen. De
            astaxanthine in VitaePro is afkomstig van de microalg Haematococcus
            Pluvialis die we in IJsland kweken. De productie vindt plaats in een
            gesloten systeem onder strikte kwaliteitscontrole.
          </p>
          <p>
            De kleur van de VitaePro capsule kan wat ongelijkmatig zijn, dit
            komt doordat de capsules transparant zijn.&nbsp;De gelatine waarvan
            de capsule gemaakt is is gemaakt van vis.
          </p>
          <p>
            De plantaardige&nbsp;stoffen&nbsp;
            <strong>luteïne en zeaxanthine</strong>&nbsp;in VitaePro zijn
            afkomstig van de bloem Tagetes erecta.&nbsp;De plant komt
            oorspronkelijk uit Latijns-Amerika en wordt van oudsher in
            verschillende culturen gegeten.&nbsp;Luteïne en zeaxanthine zijn ook
            voedingsstoffen die in het netvlies van het oog worden aangetroffen.
          </p>
          <p>
            De{" "}
            <a title="Vetten" href="https://www.vitaepro.nl/kennisbank/vetten/">
              <strong>omega 3-vetzuren</strong>
            </a>
            &nbsp;in VitaePro zijn afkomstig van Calamari, een soort inktvis die
            niet wordt bedreigd en daarom een ​​verantwoorde keuze is als bron
            van omega-3.&nbsp;Calamari bevat een hoog aandeel DHA wat gunstig is
            voor het hart, de hersenen en het gezichtsvermogen *
          </p>
          <p>
            <sup>
              <em>
                * Het gunstige effect wordt bereikt door 250 mg DHA per dag te
                consumeren.
              </em>
            </sup>
          </p>
          <p>
            <a
              title="Alles over Vitamine E"
              href="https://www.vitaepro.nl/kennisbank/vitamines/vitamine-e/"
            >
              <strong>Vitamine E</strong>
            </a>
            &nbsp;is een antioxidant die cellen helpt beschermen tegen
            oxidatieve stress.&nbsp;Zoals met de meeste andere antioxidanten
            wordt vitamine E gevonden in kleurrijke groenten en fruit, zoals
            rode paprika's, groene bladgroenten en gedroogde
            abrikozen.&nbsp;Andere bronnen van vitamine E zijn plantaardige
            oliën, volle granen, tarwekiemen en zemelen, noten en zaden.&nbsp;De
            vitamine E in VitaePro wordt gewonnen uit sojabonen.
          </p>
          <p>
            <a
              title="Alles over Vitamine D"
              href="https://www.vitaepro.nl/kennisbank/vitamines/vitamine-d/"
            >
              <strong>Vitamine D</strong>
            </a>
            &nbsp;is belangrijk voor het behoud van soepele spieren en sterke
            botten en tanden. Daarnaast ondersteunt vitamine D het
            immuunsysteem. De Vitamine D in VitaePro wordt gewonnen uit het vet
            in schapenwol (lanoline).
          </p>
          <p>
            <span className="TrackChangeTextInsertion TrackedChange SCXW163611030 BCX0">
              <span
                className="TextRun SCXW163611030 BCX0"
                lang="NL-NL"
                // xml:lang="NL-NL"
                data-contrast="auto"
              >
                <span className="NormalTextRun SCXW163611030 BCX0">
                  VitaePro is ook verrijkt met{" "}
                  <a
                    title="Alles over polyfenolen"
                    href="https://www.vitaepro.nl/kennisbank/polyfenolen/"
                  >
                    <strong>polyfenolen</strong>
                  </a>{" "}
                  uit olijfextract. U kent polyfenolen misschien als stoffen die
                  voorkomen in extra{" "}
                </span>
              </span>
            </span>
            <span className="TrackChangeTextInsertion TrackedChange SCXW163611030 BCX0">
              <span
                className="TextRun SCXW163611030 BCX0"
                lang="NL-NL"
                // xml:lang="NL-NL"
                data-contrast="auto"
              >
                <span className="SpellingError SCXW163611030 BCX0">vierge</span>
              </span>
            </span>
            <span className="TrackChangeTextInsertion TrackedChange SCXW163611030 BCX0">
              <span
                className="TextRun SCXW163611030 BCX0"
                lang="NL-NL"
                // xml:lang="NL-NL"
                data-contrast="auto"
              >
                <span className="NormalTextRun SCXW163611030 BCX0">
                  &nbsp;olijfolie, koffie, pure cacao en rode wijn.
                </span>
              </span>
            </span>
            <span className="TrackChangeTextInsertion TrackedChange SCXW163611030 BCX0">
              <span
                className="TextRun SCXW163611030 BCX0"
                lang="NL-NL"
                // xml:lang="NL-NL"
                data-contrast="auto"
              >
                <span className="NormalTextRun SCXW163611030 BCX0">
                  &nbsp;De&nbsp;
                </span>
              </span>
            </span>
            <span className="TrackChangeTextInsertion TrackedChange SCXW163611030 BCX0">
              <span
                className="TextRun SCXW163611030 BCX0"
                lang="NL-NL"
                // xml:lang="NL-NL"
                data-contrast="auto"
              >
                <span className="NormalTextRun SCXW163611030 BCX0">
                  polyfenolen
                </span>
              </span>
            </span>
            <span className="TrackChangeTextInsertion TrackedChange SCXW163611030 BCX0">
              <span
                className="TextRun SCXW163611030 BCX0"
                lang="NL-NL"
                // xml:lang="NL-NL"
                data-contrast="auto"
              >
                <span className="NormalTextRun SCXW163611030 BCX0">
                  &nbsp;in
                </span>
              </span>
            </span>
            <span className="TrackChangeTextInsertion TrackedChange SCXW163611030 BCX0">
              <span
                className="TextRun SCXW163611030 BCX0"
                lang="NL-NL"
                // xml:lang="NL-NL"
                data-contrast="auto"
              >
                <span className="NormalTextRun SCXW163611030 BCX0">
                  &nbsp;VitaePro komen uit olijfolie.&nbsp;
                </span>
              </span>
            </span>
            <span className="TrackChangeTextInsertion TrackedChange SCXW163611030 BCX0">
              <span
                className="TextRun SCXW163611030 BCX0"
                lang="NL-NL"
                // xml:lang="NL-NL"
                data-contrast="auto"
              >
                <span className="NormalTextRun SCXW163611030 BCX0">&nbsp;</span>
              </span>
            </span>
          </p>
          <div className="d-block p-3 p-md-4 bg-light text-center mb-3">
            <p className="p-0 m-0 text-center">
              VitaePro bevat gedocumenteerd actieve ingrediënten voor
              belangrijke functies in het lichaam.
            </p>
          </div>
          <h4
            className="text-primary font-weight-bold p-0 m-0 "
            style={{ textAlign: "left" }}
          >
            Ingrediënten
          </h4>
          <p>
            Calamarine® (<strong>inktvis</strong>&nbsp;olie, bevat rozemarijn
            extract), capsule (<strong>visgelatine</strong>, bevochtigingsmiddel
            (glycerol)), L-ascorbinezuur (vitamine C), Indiase wierook extract
            (Boswellia serrata), olijfolie, olijfextract (polyfenolen uit
            olijfolie), plantenextract van Afrikaantjes (Tagetes erecta),
            D-alfa-tocoferol (vitamine E), astaxanthine van bloedregenalg{" "}
            <em>Haematococcus pluvialis</em>, vulstof (siliciumdioxide),
            L-selenomethionine (seleen), emulgator (
            <strong>sojalecithinen</strong>), cholecalciferol (vitamine D),
            cyanocobalamine (vitamine B12), antioxidanten (tocoferolrijk
            extract, ascorbylpalmitaat).
          </p>
          <h4 className="text-primary font-weight-bold p-0 m-0 mb-2">
            Voedingswaarden
          </h4>
          <table className="default" border="1" width="100%">
            <tbody>
              <tr>
                <td width="60%">
                  <strong>Ingrediënten per dagelijkse dosis</strong>
                </td>
                <td width="20%">
                  <strong>1&nbsp;capsule</strong>
                </td>
                <td width="20%">&nbsp;</td>
              </tr>
              <tr>
                <td>Astaxanthine</td>
                <td>2,0 mg</td>
                <td>&nbsp;</td>
              </tr>
              <tr className="bglight">
                <td>Luteïne, zeaxanthine</td>
                <td>7,2 mg</td>
                <td>&nbsp;</td>
              </tr>
              <tr className="bglight">
                <td>
                  Indiase wierook extract&nbsp;<em>(Boswellia serrata)</em>
                </td>
                <td>100 mg</td>
                <td>&nbsp;</td>
              </tr>
              <tr className="bglight">
                <td>
                  Vitamine B<sub>12</sub>
                </td>
                <td>2,5&nbsp;µg</td>
                <td>100%*</td>
              </tr>
              <tr className="bgdark">
                <td>Vitamine C</td>
                <td>120 mg</td>
                <td>150%*</td>
              </tr>
              <tr className="bglight">
                <td>
                  Vitamine D<sub>3</sub>
                </td>
                <td>15 µg</td>
                <td>300%*</td>
              </tr>
              <tr className="bgdark">
                <td>Vitamine E</td>
                <td>12 mg a-TE</td>
                <td>100%*</td>
              </tr>
              <tr className="bglight">
                <td>Seleen</td>
                <td>80 µg</td>
                <td>146%*</td>
              </tr>
              <tr className="bgdark">
                <td>
                  Omega-3-vetzuren totaal <em>(als triglyceriden)</em> <br />-
                  waarvan DHA <br />- waarvan EPA
                </td>
                <td>
                  130 mg <br />
                  90 mg <br />
                  35 mg
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Hydroxytyrosol (polyfenolen uit olijfolie)</td>
                <td>5 mg</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
          <h4 className="text-primary font-weight-bold p-0 m-0 pt-3">
            Aanbevolen gebruik
          </h4>
          <p>
            1-2 capsules per dag, bij voorkeur tijdens de maaltijd innemen. De
            aanbevolen dosering niet overschrijden. Niet aanbevolen voor
            zwangere vrouwen en voor kinderen onder de 14 jaar.
          </p>
          <div className="d-block p-3 p-md-4 mb-3 bg-light">
            <h4 className="text-primary font-weight-bold p-0 m-0 text-center">
              Wetenschappelijk Onderzoek
            </h4>
            <p className="font-weight-bold">
              VitaePro is een voedingssupplement dat enkel gedocumenteerd
              actieve ingrediënten bevat die speciaal gericht zijn op spieren en
              gewrichten, het hart en die helpen vermoeidheid te verminderen.
            </p>
            <p>
              Dat mogen wij niet zomaar zeggen: VitaePro moet aan strenge, door
              de Europese Unie opgelegde regelgeving voldoen. Die regelgeving is
              bedoeld om de consument duidelijkheid te verschaffen over de rol
              van ingredienten in supplementen bij het functioneren van het
              lichaam. Alles wat beloofd wordt in reclame uitingen en op
              verpakkingen moet wetenschappelijk getoetst zijn.
            </p>
            <p className="p-0 m-0">
              Benieuwd hoe dat werkt? Of welke onderzoeken er ten grondslag
              liggen aan de effecten die VitaePro beloofd?{" "}
              <a href="https://www.vitaepro.nl/onderzoek/">Lees meer »</a>
            </p>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ModalIngredienten;
